import { SERVICES } from '../../utils/constants'
import { formatNumber, formatPrice } from '../../utils/format'
import { getAMLStatusError, getServiceStatusError } from '../../utils/functions'

const _getAMLStatusError = (salesPoints) => salesPoints.some((salesPoint) => getAMLStatusError(salesPoint))
const _getServiceStatusError = (service, salesPoints) =>
  salesPoints.some((salesPoint) => getServiceStatusError(service, salesPoint))

export const getServicesStatusErrors = (salesPoints) => {
  return {
    AMA: _getServiceStatusError(SERVICES.AMA, salesPoints),
    AML: _getAMLStatusError(salesPoints),
    PADLeader: _getServiceStatusError(SERVICES.PADLeader, salesPoints),
    PADHKOnly: _getServiceStatusError(SERVICES.PADHKOnly, salesPoints),
  }
}

export const getSums = (distributor, errors) => {
  const amounts = distributor.totalAMLAmount + distributor.totalAMAAmount
  const sums = [
    {
      title: 'Montant AML/AMA déjà versé à T1/T2/T3',
      value: formatPrice(distributor.payedAmount),
      error: false,
    },
    {
      title: 'Montant AML/AMA total annuel',
      value: formatPrice(amounts),
      error: errors.AML || errors.AMA,
    },
    {
      title: 'Montant AMA/AML T4',
      value: formatPrice(amounts - distributor.payedAmount),
      error: errors.AML || errors.AMA,
    },
  ]
  return sums
}

export const getCardsValues = (distributor, errors) => {
  return [
    {
      title: 'Totaux',
      volume: formatNumber(
        distributor.totalAMLVolume +
          distributor.totalAMAVolume +
          distributor.totalPADHKOnlyVolume +
          distributor.totalPADLeaderVolume,
      ),
      amount: Object.values(errors).some((err) => err === true)
        ? '-'
        : formatNumber(
            distributor.totalAMLAmount +
              distributor.totalAMAAmount +
              distributor.totalPADHKOnlyAmount +
              distributor.totalPADLeaderAmount,
          ),
      className: 'total',
    },
    {
      title: 'AML',
      volume: formatNumber(distributor.totalAMLVolume),
      amount: errors.AML ? '-' : formatNumber(distributor.totalAMLAmount),
      className: 'children',
    },
    {
      title: 'AMA',
      volume: formatNumber(distributor.totalAMAVolume),
      amount: errors.AMA ? '-' : formatNumber(distributor.totalAMAAmount),
      className: 'children',
    },
    {
      title: 'PAD HK Leader',
      volume: formatNumber(distributor.totalPADLeaderVolume),
      amount: errors.PADLeader ? '-' : formatNumber(distributor.totalPADLeaderAmount),
      className: 'children',
    },
    {
      title: 'PAD HK Seul',
      volume: formatNumber(distributor.totalPADHKOnlyVolume),
      amount: errors.PADHKOnly ? '-' : formatNumber(distributor.totalPADHKOnlyAmount),
      className: 'children',
    },
  ]
}
