import { Button, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import {
  AMOUNT_LABEL,
  AM_VOLUME_LABEL,
  CODE_LABEL,
  DISTRIBUTOR_LABEL,
  PAD_VOLUME_LABEL,
  SALESPOINT_NUMBER_LABEL,
  STATUS_LABEL,
} from '../../../../utils/constants.js'
import { downloadExcel } from '../../../../utils/export.js'
import { formatNumber } from '../../../../utils/format.js'
import DetailsButton from '../../../Button/DetailsButton/index.jsx'
import BaseTable from '../../BaseTable/index.jsx'
import TableTitle from '../../TableTitle/index.js'

DistribTableIndep.propTypes = {
  distributors: PropTypes.array.isRequired,
}

export default function DistribTableIndep({ distributors }) {
  const headCells = [
    { id: 'code', label: CODE_LABEL, align: 'left' },
    { id: 'name', label: DISTRIBUTOR_LABEL, align: 'left' },
    { id: 'status', label: STATUS_LABEL, align: 'left' },
    { id: 'AMVolume', label: AM_VOLUME_LABEL },
    { id: 'PADVolume', label: PAD_VOLUME_LABEL },
    { id: 'rowNumber', label: SALESPOINT_NUMBER_LABEL },
    { id: 'amount', label: AMOUNT_LABEL },
    { id: 'action', label: '' },
  ]

  let excelData = []

  let distributorsDatas = distributors.map((distributor) => {
    const salesPointsLength = Object.keys(distributor.salesPoints).length
    const amVolume = formatNumber(distributor.totalAMLVolume + distributor.totalAMAVolume)
    const padVolume = formatNumber(distributor.totalPADHKOnlyVolume + distributor.totalPADLeaderVolume)
    const amount = formatNumber(distributor.payedAmount)

    excelData.push({
      [CODE_LABEL]: distributor.tangramCode,
      [DISTRIBUTOR_LABEL]: distributor.name,
      [STATUS_LABEL]: distributor.status?.name,
      [AM_VOLUME_LABEL]: amVolume,
      [PAD_VOLUME_LABEL]: padVolume,
      [SALESPOINT_NUMBER_LABEL]: salesPointsLength,
      [AMOUNT_LABEL]: amount,
    })

    return {
      id: distributor.id,
      code: distributor.tangramCode,
      name: distributor.name,
      status: distributor.status?.name,
      AMVolume: amVolume,
      PADVolume: padVolume,
      rowNumber: salesPointsLength,
      amount: amount,
      action: <DetailsButton to={`/distributor/${distributor.id}`} />,
    }
  })

  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={10} sm={8}>
          <TableTitle text="Synthèse de mes distributeurs" />
        </Grid>
        <Grid item xs={2} sm={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => downloadExcel(excelData, 'export_distributeurs.xlsx')}
          >
            Exporter
          </Button>
        </Grid>
      </Grid>

      <BaseTable defaultOrderBy="name" hasPadding headCells={headCells} tableData={distributorsDatas} />
    </>
  )
}
