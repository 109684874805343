import API from '../../api/api'
import { salesPointQuery } from '../../api/queries/salesPointQueries'
import { FILE_ACTIONS, SERVICES } from '../../utils/constants'
import { formatNumber } from '../../utils/format'
import { getAMLStatusError, getServiceStatusError } from '../../utils/functions'

export const getNewState = async (currentId, salesPoints) => {
  const salesPointIndex = salesPoints.findIndex((salesPoint) => salesPoint.id === parseInt(currentId))
  const result = await API.queryAPI(salesPointQuery(currentId))
  return {
    salesPoint: result.salesPoint,
    errors: getServicesStatusErrors(result.salesPoint),
    totalSum: {
      AML: { amount: result.salesPoint.AMLAmount, volume: result.salesPoint.AMLVolume },
      AMA: { amount: result.salesPoint.AMAAmount, volume: result.salesPoint.AMAVolume },
      PAD: {
        amount: result.salesPoint.PADHKOnlyAmount + result.salesPoint.PADLeaderAmount,
        volume: result.salesPoint.PADHKOnlyVolume + result.salesPoint.PADLeaderVolume,
      },
    },
    nextSalesPointId: salesPoints[salesPointIndex + 1]?.id ?? null,
    isCompleted: false,
    stopAML: result.salesPoint.files.some((file) => file.stopAML),
  }
}

export const getFormTitle = (action) => {
  if (action === FILE_ACTIONS.addProduct) return 'Ajouter une marque'
  else if (action === FILE_ACTIONS.addFile) return 'Ajouter un dossier'
  else if (action === FILE_ACTIONS.updateDiscount) return "Modifier l'€/HL"
  else if (action === FILE_ACTIONS.updateDates) return 'Modifier les dates'
}

export const getServicesStatusErrors = (salesPoint) => {
  return {
    AML: getAMLStatusError(salesPoint),
    AMA: getServiceStatusError(SERVICES.AMA, salesPoint),
    PAD: getServiceStatusError(SERVICES.PADLeader, salesPoint) || getServiceStatusError(SERVICES.PADHKOnly, salesPoint),
  }
}

export const getSumCards = (salesPoint, total, errors) => {
  return [
    { title: 'Nombre de lignes', value: salesPoint.files.length, error: false },
    {
      title: 'Volume total déclaré (en HL)',
      value: formatNumber(total.AML.volume + total.AMA.volume + total.PAD.volume),
      error: false,
    },
    {
      title: 'Montant total annuel AMA/AML (en €)',
      value: formatNumber(total.AML.amount + total.AMA.amount),
      error: errors.AML || errors.AMA,
    },
    {
      title: 'Montant PAD annuel (en €)',
      value: formatNumber(total.PAD.amount),
      error: errors.PAD,
    },
  ]
}

export const getFilesTableData = (files) => {
  const AMLFiles = files.filter((file) => file.service.name.substring(0, 3) === SERVICES.AML)
  const categorizedFiles = []
  for (const file of AMLFiles) {
    const categoryId = `${file.product.category.id}${file.sparcCode}`
    const existingCategory = categorizedFiles.find((item) => item.id === categoryId)
    if (existingCategory) {
      const index = categorizedFiles.indexOf(existingCategory)
      categorizedFiles[index].files.push(file)
      categorizedFiles[index].volume += file.volume
      categorizedFiles[index].amount += file.volume * file.discount ?? 0
      if (file.error) categorizedFiles[index].error = file.error
    } else
      categorizedFiles.push({
        ...file,
        id: categoryId,
        type: file.product.category.name,
        volume: file.volume ?? 0,
        amount: file.volume * file.discount ?? 0,
        files: [file],
      })
  }

  const otherFiles = files.filter((file) => file.service.name.substring(0, 3) !== SERVICES.AML)

  return { categorizedFiles, otherFiles }
}

export const getNewTotal = (files, service, value) =>
  files
    .filter((file) => file.service.name.substring(0, 3) === service)
    .reduce((prev, current) => prev + current[value], 0)

export const getNewValueState = (currentState, newFile) => {
  const fileIndex = currentState.salesPoint.files.findIndex((file) => file.id === newFile.id)
  currentState.salesPoint.files[fileIndex] = newFile
  return {
    alert,
    salesPoint: {
      ...currentState.salesPoint,
      files: currentState.salesPoint.files,
    },
    totalSum: {
      ...currentState.totalSum,
      [newFile.service.name.substring(0, 3)]: {
        amount: getNewTotal(currentState.salesPoint.files, newFile.service.name.substring(0, 3), 'amount'),
        volume: getNewTotal(currentState.salesPoint.files, newFile.service.name.substring(0, 3), 'volume'),
      },
    },
  }
}
