import { Button, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import {
  DISTRIBUTOR_LABEL,
  FOLDERS_NUMBER,
  STATUS_LABEL,
  VALIDATION_RATE_FB,
  VALIDATION_RATE_HE,
} from '../../../../utils/constants.js'
import { downloadExcel } from '../../../../utils/export.js'
import { formatNumber } from '../../../../utils/format.js'
import DetailsButton from '../../../Button/DetailsButton/index.jsx'
import BaseTable from '../../BaseTable/index.jsx'
import TableTitle from '../../TableTitle/index.js'

DistributorTableFb.propTypes = {
  distributors: PropTypes.array.isRequired,
}

export default function DistributorTableFb({ distributors }) {
  const headCells = [
    { id: 'name', label: DISTRIBUTOR_LABEL, align: 'left' },
    { id: 'status', label: STATUS_LABEL, align: 'left' },
    { id: 'foldersNumber', label: FOLDERS_NUMBER },
    { id: 'completionHe', label: VALIDATION_RATE_HE },
    { id: 'completionFb', label: VALIDATION_RATE_FB },
    { id: 'action', label: '' },
  ]

  let excelData = []

  let distributorsDatas = distributors.map((distributor) => {
    const fbPadFiles = distributor.fbPadFiles
    const filteredPadFilesHe = fbPadFiles.filter((padFile) => padFile.service.indexOf('Heineken') !== -1)
    const padFilesHeSize = filteredPadFilesHe.length
    const padFileFbSize = fbPadFiles.length - padFilesHeSize
    const completionHe = filteredPadFilesHe.filter((padFile) => !!padFile.status).length
    const completionFb = fbPadFiles.filter(
      (padFile) => padFile.service.indexOf('France Boissons') !== -1 && !!padFile.status,
    ).length
    const amount = formatNumber(distributor.payedAmount)

    excelData.push({
      [DISTRIBUTOR_LABEL]: distributor.name,
      [STATUS_LABEL]: distributor.status?.name,
      [FOLDERS_NUMBER]: fbPadFiles.length,
      [VALIDATION_RATE_HE]: completionHe,
      [VALIDATION_RATE_FB]: completionFb,
    })

    return {
      id: distributor.id,
      name: distributor.name,
      status: distributor.status?.name,
      foldersNumber: distributor.fbPadFiles.length,
      completionHe: completionHe + '/' + padFilesHeSize,
      completionFb: completionFb + '/' + padFileFbSize,
      action: <DetailsButton to={`/france-boissons/${distributor.id}`} />,
    }
  })

  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={10} sm={8}>
          <TableTitle text="Synthèse de mes distributeurs" />
        </Grid>
        <Grid item xs={2} sm={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => downloadExcel(excelData, 'export_distributeurs.xlsx')}
          >
            Exporter
          </Button>
        </Grid>
      </Grid>

      <BaseTable defaultOrderBy="name" hasPadding headCells={headCells} tableData={distributorsDatas} />
    </>
  )
}
