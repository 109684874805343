import { Box, Grid } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'

SumCard.propTypes = {
  disclaimer: PropTypes.string,
  horizontalDisplay: PropTypes.bool,
  sums: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      error: PropTypes.bool,
    }),
  ).isRequired,
}

function displayVertical(sum, index) {
  return (
    <Grid key={index} item xs={12} sm={6}>
      <Box>
        <Typography variant="overline">{sum.title}</Typography>
      </Box>
      <Box fontWeight="fontWeightBold" mb={1}>
        {sum.error ? '-' : sum.value}
      </Box>
    </Grid>
  )
}

function displayHorizontal(sum, index) {
  return (
    <>
      <Grid key={index} xs={9}>
        <Box>
          <Typography variant="overline">{sum.title}</Typography>
        </Box>
      </Grid>
      <Grid key={index} item xs={3}>
        <Box fontWeight="fontWeightBold" mb={1}>
          {sum.error ? '-' : sum.value}
        </Box>
      </Grid>
    </>
  )
}

function displayDisclaimer(disclaimer) {
  return (
    <Grid item xs={12}>
      <Typography align="center" variant="body2">
        {disclaimer}
      </Typography>
    </Grid>
  )
}

export default function SumCard({ disclaimer, horizontalDisplay, sums }) {
  return (
    <Card>
      <CardContent variant="outlined">
        <Grid container spacing={2}>
          {sums.map((sum, index) => (horizontalDisplay ? displayHorizontal(sum, index) : displayVertical(sum, index)))}
          {disclaimer ? displayDisclaimer(disclaimer) : null}
        </Grid>
      </CardContent>
    </Card>
  )
}
