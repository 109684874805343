import { TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

export default class TextFieldForm extends React.Component {
  render() {
    return (
      <TextField
        id={this.props.name}
        name={this.props.name}
        value={this.props.value}
        onChange={this.props.onChange}
        label={this.props.label}
        type="text"
        InputLabelProps={{
          shrink: true,
        }}
        margin="normal"
        variant="outlined"
        fullWidth
        disabled={this.props.disabled ?? false}
        required={this.props.required}
        error={this.props.error ?? false}
        helperText={this.props.error ? 'Champ obligatoire' : ''}
        multiline={this.props.multiLine}
      />
    )
  }
}

TextFieldForm.propTypes = {
  name: PropTypes.string.required,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.bool,
  multiLine: PropTypes.bool,
}
