import { Grid, Paper } from '@material-ui/core'
import React from 'react'
import API from '../../api/api'
import { distributorsByUserQuery } from '../../api/queries/distributorQueries'
import { regionsQuery } from '../../api/queries/regionQueries'
import Select from '../../components/Form/SelectForm'
import DistribTableFb from '../../components/Table/DistribTable/DistribTableFb/index'
import DistribTableIndep from '../../components/Table/DistribTable/DistribTableIndep/index'
import LoadingTable from '../../components/Table/LoadingTable'
import { UserContext } from '../../contexts/userContext'
import { sortArrayByValue } from '../../utils/functions'
// import { permissions } from '../../utils/role'

const SELECT = {
  distributor: 1,
  region: 2,
}
export default class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFbPage: this.props.location.pathname === '/fb',
      commercials: [],
      distributors: [],
      filteredDistributors: [],
      manager: null,
      regions: [],
      selectedDistributor: '',
      selectedRegion: '',
    }
  }

  static contextType = UserContext

  async _getUserData() {
    const user = this.context
    if (this.state.distributors.length > 0 || !user?.userLogin) return
    const userResult = await API.queryAPI(distributorsByUserQuery(user.userLogin))
    const userData = userResult.user
    const distributors = userData.distributors.filter((distrib) =>
      distrib !== null && this.state.isFbPage ? !!distrib.fbDistribCode : !!distrib.tangramCode,
    )
    return {
      manager: userResult.user.manager,
      distributors: distributors,
      filteredDistributors: distributors,
      commercials: userResult.user?.commercials?.map((com) => {
        com.fullname = `${com.firstname} ${com.lastname}`
        return com
      }),
    }
  }

  async componentDidMount() {
    const regionsResult = await API.queryAPI(regionsQuery)
    const filteredRegions = regionsResult.regions.filter((region) => region.id !== 4)
    const userData = await this._getUserData()

    this.setState({
      ...userData,
      regions: filteredRegions,
    })
  }

  async componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ isFbPage: this.props.location.pathname })
      const userData = await this._getUserData()
      this.setState(userData)
    }
  }

  _getList(items) {
    return [{ id: null, name: 'Tout afficher' }].concat(sortArrayByValue(items, 'name'))
  }

  _handleFilter(select, value) {
    if (value === null) {
      this.setState({ filteredDistributors: this.state.distributors, selectedRegion: '', selectedDistributor: '' })
      return
    }
    if (select === SELECT.distributor) {
      const distributors = this.state.distributors.filter((distrib) => distrib.id === value)
      this.setState({ filteredDistributors: distributors, selectedRegion: '', selectedDistributor: value })
    } else if (select === SELECT.region) {
      const distributors = this.state.distributors.filter((distrib) => {
        if (value === 4) return distrib.tangramCode === null
        else return distrib.salesPoints.filter((salesPoint) => salesPoint.region.id === value).length > 0
      })
      this.setState({ filteredDistributors: distributors, selectedDistributor: '', selectedRegion: value })
    }
  }

  render() {
    return (
      <Grid container>
        <>
          {!this.state.isFbPage && (
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Select
                  name="regions"
                  select
                  value={this.state.selectedRegion}
                  onChange={(e) => this._handleFilter(SELECT.region, e.target.value)}
                  label="Région"
                  items={this._getList(this.state.regions)}
                  valueName="id"
                  text="name"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  name="distributor"
                  select
                  value={this.state.selectedDistributor}
                  onChange={(e) => this._handleFilter(SELECT.distributor, e.target.value)}
                  label="Distributeur"
                  items={this._getList(this.state.distributors)}
                  valueName="id"
                  text="name"
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} component={Paper}>
            {!this.state.distributors.length > 0 ? (
              <LoadingTable />
            ) : this.state.isFbPage ? (
              <DistribTableFb distributors={this.state.filteredDistributors} />
            ) : (
              <DistribTableIndep distributors={this.state.filteredDistributors} />
            )}
          </Grid>
        </>
      </Grid>
    )
  }
}
