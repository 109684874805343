import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { getComparator, stableSort } from './functions'
import './style.css'

BaseTable.propTypes = {
  collapsibleTable: PropTypes.func,
  customCells: PropTypes.func,
  hasPadding: PropTypes.bool,
  headCells: PropTypes.array.isRequired,
  defaultOrderBy: PropTypes.string.isRequired,
  isCollapsed: PropTypes.bool,
  selectedRowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tableData: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
}
BaseTable.defaultProps = {
  hasPadding: false,
  isCollapsed: false,
}

export default function BaseTable({
  collapsibleTable,
  customCells,
  defaultOrderBy,
  isCollapsed,
  hasPadding,
  headCells,
  selectedRowId,
  tableData,
  onSelect,
}) {
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState(defaultOrderBy)
  function _handleRequestSort(property) {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const _cellStyle = (error, lineStatus = null, replaceLine = null) => {
    if (lineStatus === 2) return { color: '#4900FF' }
    else if (lineStatus === 4) return { color: '#FF4949' }
    else if (lineStatus === 1 && replaceLine !== null) return { color: '#337b3a' }
    else return error ? { color: '#4900FF' } : {}
  }

  return (
    <TableContainer>
      <Table size="small">
        {!isCollapsed && (
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  padding={hasPadding ? 'normal' : 'none'}
                  align="center"
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={() => _handleRequestSort(headCell.id)}
                  >
                    <strong>{headCell.label}</strong>
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {stableSort(tableData, getComparator(order, orderBy)).map((item, index, array) => (
            <React.Fragment key={`tablebody-${item.id}`}>
              <TableRow
                onClick={() => (!isCollapsed && onSelect ? onSelect(item) : '')}
                style={selectedRowId === item.id ? { backgroundColor: '#C8C8C8' } : {}}
              >
                {headCells.map((cell) => (
                  <TableCell
                    key={`${isCollapsed ? 'collapsed-' : ''}${cell.id}-${item.id}`}
                    padding={hasPadding ? 'normal' : 'none'}
                    align={cell.align ?? 'center'}
                    style={{
                      ..._cellStyle(item.error, item.lineStatus, item.replaceLine),
                      width: cell.width ?? 'auto',
                    }}
                  >
                    {(customCells && customCells(cell.id, item, index, array)) ?? item[cell.id]}
                  </TableCell>
                ))}
              </TableRow>
              {!isCollapsed && collapsibleTable && collapsibleTable(item)}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
