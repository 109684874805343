import API from '../../api/api'
import {
  excelExportDistributor,
  excelExportFbDistributorQuery,
  ksiopExportDistributor,
} from '../../api/queries/distributorQueries'
import { calculatePaybackHT, calculatePaybackTTC } from '../../components/Form/FbPadForm/functions'
import { ALERT_SEVERITY, CONTENTIOUS_STATUS, ERRORS, FILE_TARGET, SERVICES } from '../../utils/constants'
import { downloadBlob, downloadExcel } from '../../utils/export'
import { getSalesPointSatus } from '../../utils/functions'

const WARNING_ALERT = {
  message: 'Pas de résultat pour cet export',
  severity: ALERT_SEVERITY.warning,
  success: false,
}

function addDataWithPadding(padding, before, data) {
  let res = ''

  if (before) res += ' '.repeat(padding - data.length)
  res += data
  if (!before) res += ' '.repeat(padding - data.length)
  return res
}

export const exportTxt = async (target, distributors) => {
  let query = '{'
  for (let distributor of distributors) {
    query += `distrib${distributor.id}: ${ksiopExportDistributor(distributor.id)}`
  }
  query += '}'

  const result = await API.queryAPI(query)
  let txt = ''
  const pastYear = new Date().getFullYear() - 1
  let isFirstFile = true
  Object.values(result).forEach((distributor, indexDistributor) => {
    distributor.salesPoints.forEach((salesPoint, indexSalesPoint) => {
      salesPoint.files.forEach((file) => {
        if (!file.volume) return
        if (file.service.name.substring(0, 2) !== target.substring(6, 8).toUpperCase()) return
        if (file.cassiopaeCode === null) return
        if (!(indexDistributor === 0 && indexSalesPoint === 0 && isFirstFile)) txt += '\n'
        isFirstFile = false
        txt += addDataWithPadding(10, true, '')
        txt += addDataWithPadding(10, true, file.cassiopaeCode === null ? '' : file.cassiopaeCode)
        txt += addDataWithPadding(4, true, pastYear)
        txt += addDataWithPadding(4, true, '0012')
        txt += addDataWithPadding(2, true, '01')
        txt += addDataWithPadding(2, true, '')
        txt += addDataWithPadding(40, false, file.product.code)
        txt += addDataWithPadding(20, false, 'MOYENNE')
        txt += addDataWithPadding(14, true, file.volume === null ? '0.000' : file.volume.toFixed(3))
        for (let i = 0; i < 8; i++) txt += addDataWithPadding(14, true, '0.000')
      })
    })
  })
  if (!txt) return WARNING_ALERT
  const blob = new Blob([txt], { type: 'text/plain' })
  downloadBlob(
    blob,
    target === FILE_TARGET.ksiopAML ? `FichierVolumesIndepAML${pastYear}.txt` : `FichierVolumesIndepPAD${pastYear}.txt`,
  )
  return { success: true }
}

function _endingDate(file) {
  let date = file.endingDate
  if (file.contentious?.id === CONTENTIOUS_STATUS.gest) {
    date = file.endingDate
  } else if (file.contentiousDate !== null) {
    date = file.contentiousDate
  }
  return date
}

const prepareIndepData = async (target, distributors) => {
  let query = '{'
  for (let distributor of distributors) {
    query += `distrib${distributor.id}: ${excelExportDistributor(distributor.id)}`
  }
  query += '}'

  try {
    const result = await API.queryAPI(query)

    const amlErrors = []
    const amaErrors = []
    const padErrors = []
    const data = []

    if (!result) return []

    for (const distributor of Object.values(result)) {
      if (target === FILE_TARGET.excelEmailsDistributors) {
        data.push({
          Distributeur: distributor.name,
          'Code TANGRAM': distributor.tangramCode,
          Email: distributor.email,
        })
      } else {
        for (const salesPoint of distributor.salesPoints) {
          const displayOnlyFileWithVolume = salesPoint.files.some((file) => file.volume)
          for (const file of salesPoint.files) {
            if (displayOnlyFileWithVolume && !file.volume) continue
            if (target === FILE_TARGET.excelAmlStop && file.stopAML === false) continue
            if (file.error?.id != null || salesPoint.error?.id != null) {
              if (salesPoint.error?.id === ERRORS.salesPointAttachment) {
                if (file.service.name === SERVICES.AML && amlErrors.indexOf(distributor.name) === -1)
                  amlErrors.push(distributor.name)
                else if (file.service.name === SERVICES.AMA && amaErrors.indexOf(distributor.name) === -1)
                  amaErrors.push(distributor.name)
                else if (
                  file.service.name.substring(0, 3) === SERVICES.PAD &&
                  padErrors.indexOf(distributor.name) === -1
                )
                  padErrors.push(distributor.name)
              } else {
                if (file.service.name === SERVICES.AMA && amaErrors.indexOf(distributor.name) === -1)
                  amaErrors.push(distributor.name)
                else if (
                  file.service.name.substring(0, 3) === SERVICES.PAD &&
                  padErrors.indexOf(distributor.name) === -1
                )
                  padErrors.push(distributor.name)
              }
            }

            if (target === FILE_TARGET.excelError && file.error === null && salesPoint.error === null) continue
            data.push({
              Prestation: file.service.name,
              Distributeur: distributor.name,
              'Code TANGRAM': distributor.tangramCode,
              'Montant T1/T2/T3': distributor.payedAmount,
              'Statut distributeur': distributor.status?.name,
              'Point de vente': salesPoint.name,
              'Statut point de vente': getSalesPointSatus(salesPoint.files),
              Ville: salesPoint.address.city,
              'Code Postal': salesPoint.address.zipcode,
              Adresse: salesPoint.address.address,
              Catégorie: file.service?.name === SERVICES.AML ? file.product.category?.name : '',
              Marque: file.product.name,
              '€/HL': file.discount,
              Volume: file.volume || 0,
              Montant: (file.discount * file.volume).toFixed(2) || 0,
              'Date de début': file.startingDate,
              'Date de fin': _endingDate(file),
              'Objectif de volume': file.objectiveVolume,
              'Objectif de montant': file.objectiveAmount,
              'Code SPARC': file.sparcCode,
              'Code CASSIOPAE': file.cassiopaeCode,
              'Code marque': file.product.code,
              'Type écart': salesPoint.error?.name ?? file.error?.name ?? '',
            })
          }
        }
      }
    }
    data.forEach((file, index) => {
      if (amlErrors.includes(file.Distributeur) && file.Prestation === SERVICES.AML) data[index].Montant = ''
      if (amaErrors.includes(file.Distributeur) && file.Prestation === SERVICES.AMA) data[index].Montant = ''
      if (padErrors.includes(file.Distributeur) && file.Prestation.substring(0, 3) === SERVICES.PAD)
        data[index].Montant = ''
    })

    return data
  } catch (e) {
    return { success: false, severity: 'error', message: e }
  }
}

const prepareFbPadData = async (target, distributors) => {
  let query = '{'
  for (let distributor of distributors) {
    query += `distrib${distributor.id}: ${excelExportFbDistributorQuery(distributor.id)}`
  }
  query += '}'

  try {
    const result = await API.queryAPI(query)
    if (!result) return []

    const data = []

    for (const distributor of Object.values(result)) {
      for (const file of distributor.fbPadFiles) {
        for (const line of file.fbPadLines) {
          if (line.lineStatus !== 1 || (line.lineStatus === 1 && line.replaceLine === null)) continue
          data.push({
            Distributeur: distributor.name,
            'Statut distributeur': distributor.status?.name,
            'Code Cassiopae': file.cassiopaeCode,
            'Code SPARC': file.sparcCode,
            'Code Totem': file.totemCode,
            'Nbre de PdV': file.nbSalespoint,
            'Point de Vente': file.salespointName,
            Prestation: file.service,
            Statut: file.status ? 'Validé' : 'À compléter',
            'Montant annuel': file.annualAmount,
            'Remise HE': line.discount_HE,
            'Ancienne Remise HE': line.id !== line?.replaceLine?.id ? line?.replaceLine?.discount_HE : null,
            'Remise FB': line.discount_FB,
            'Ancienne Remise FB': line.id !== line?.replaceLine?.id ? line?.replaceLine?.discount_FB : null,
            'Gratuité HE': line.free_HE,
            'Ancienne Gratuité HE': line.id !== line?.replaceLine?.id ? line?.replaceLine?.free_HE : null,
            'Gratuité FB': line.free_FB,
            'Ancienne Gratuité FB': line.id !== line?.replaceLine?.id ? line?.replaceLine?.free_FB : null,
            Volume: line.volume,
            'Ancienne Volume': line.id !== line?.replaceLine?.id ? line?.replaceLine?.volume : null,
            Marque: line.product?.name,
            Catégorie: line.product?.category?.name,
            'Ristourne HT': calculatePaybackHT(line),
            'Taux de TVA': line.tax_rate,
            'Ristourne TTC': calculatePaybackTTC(line),
            Unités: line.unit,
          })
        }
      }
    }
    return data
  } catch (e) {
    return { success: false, severity: 'error', message: e }
  }
}

export const exportExcel = async (target, distributors) => {
  try {
    const data =
      target === FILE_TARGET.excelFbPAD
        ? await prepareFbPadData(target, distributors)
        : await prepareIndepData(target, distributors)

    if (data.length === 0) return WARNING_ALERT

    let fileName
    switch (target) {
      case FILE_TARGET.excelError:
        fileName = 'Hector_ExportEcarts.xlsx'
        break
      case FILE_TARGET.excelAmlStop:
        fileName = 'Hector_ExportArretAML.xlsx'
        break
      case FILE_TARGET.excelEmailsDistributors:
        fileName = 'Hector_ExportEmail.xlsx'
        break
      case FILE_TARGET.excelFbPAD:
        fileName = 'Hector_ExportPadFB.xlsx'
        break
      case FILE_TARGET.excelAll:
      default:
        fileName = 'Hector_ExportVolumes.xlsx'
        break
    }

    downloadExcel(data, fileName)
    return { success: true }
  } catch (e) {
    return { success: false, severity: 'error', message: e }
  }
}
