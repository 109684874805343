import { MenuItem, TextField } from '@material-ui/core'
import React from 'react'

export default class SelectForm extends React.Component {
  render() {
    return (
      <TextField
        id={this.props.name}
        name={this.props.name}
        select
        value={this.props.value}
        onChange={this.props.onChange}
        label={this.props.label}
        margin="normal"
        variant="outlined"
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        disabled={this.props.disabled ?? false}
        error={this.props.error ?? false}
        helperText={this.props.error ? 'Champ obligatoire' : ''}
      >
        {this.props.items?.map((item) => (
          <MenuItem key={item.id} value={item[this.props.valueName]}>
            {item[this.props.text]}
          </MenuItem>
        ))}
      </TextField>
    )
  }
}
