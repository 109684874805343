import { SERVICES } from '../../../utils/constants'
import { formatNumber } from '../../../utils/format'
import { getAMLStatusError, getServiceStatusError } from '../../../utils/functions'

export const sumKeys = {
  AMA: 'AMA',
  AML: 'AML',
  PADLeader: 'PADLeader',
  PADHKOnly: 'PADHKOnly',
  PADDistributor: 'PADHKOnlyDistributor',
}
export const sumTypes = {
  amount: 'Amount',
  volume: 'Volume',
}

export const displayValue = (keys, type, salesPoint) => {
  const values = keys.map((key) => salesPoint[`${key}${type}`])
  return values.length > 0
    ? values.length > 1
      ? formatNumber(values.reduce((a, b) => a + b)) ?? '-'
      : formatNumber(values[0]) ?? '-'
    : '-'
}

export const displayAMAmount = (salesPoint) => {
  if (getAMLStatusError(salesPoint) || getServiceStatusError(SERVICES.AMA, salesPoint)) return '-'
  return displayValue([sumKeys.AML, sumKeys.AMA], sumTypes.amount, salesPoint)
}

export const displayPADAmount = (salesPoint) => {
  if (getServiceStatusError(SERVICES.PADHKOnly, salesPoint) || getServiceStatusError(SERVICES.PADLeader, salesPoint))
    return '-'
  return displayValue([sumKeys.PADHKOnly, sumKeys.PADLeader], sumTypes.amount, salesPoint)
}

export const displayPADDistributorAmount = (salesPoint) => {
  if (getServiceStatusError(SERVICES.PADHKOnly, salesPoint) || getServiceStatusError(SERVICES.PADLeader, salesPoint))
    return '-'
  return displayValue([sumKeys.PADDistributor], sumTypes.amount, salesPoint)
}
