export const productsQuery = `{
  products {
    id
    code
    name
    category {
      id
      code
      name
    }
    rate {
      id
      value
    }
  }
}`

export const productsByMissingCategoriesQuery = (categoryIds) => `{
  productsByMissingCategories(categoryIds: [${categoryIds}]) {
    id
    code
    name
  }
}`
