import { Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import PropTypes from 'prop-types'
import React from 'react'

AlertSnackBar.propTypes = {
  open: PropTypes.bool.isRequired,
  severity: PropTypes.string,
  text: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}
AlertSnackBar.defaultProps = {
  severity: '',
}

export default function AlertSnackBar({ open, severity, text, onClose }) {
  function _handleSnackClose(_e, reason) {
    if (reason === 'clickaway') return
    onClose()
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={5000}
      onClose={_handleSnackClose}
    >
      <MuiAlert elevation={6} variant="filled" onClose={_handleSnackClose} severity={severity}>
        {text}
      </MuiAlert>
    </Snackbar>
  )
}
