export const userQuery = (userId) => {
  return `{
    user(login: "${userId}") {
      id
      login
      firstname
      role {
        id
      }
      region {
        id
      }
    }
  }`
}
