import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useState } from 'react'
import WarningMessage from '../../WarningMessage'
import AlertForm from '../AlertForm'
import DialogForm from '../DialogForm'
import TextField from '../TextFieldForm'

SalesPointForm.propTypes = {
  distributorName: PropTypes.number.string,
  open: PropTypes.bool.isRequired,
  salesPoints: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
}

export default function SalesPointForm({ distributorName, open, salesPoints, onClose, onValidate }) {
  const [state, setState] = useState({
    name: '',
    address: '',
    zipcode: '',
    city: '',
  })
  const [openAlert, setAlert] = useState(false)

  const _handleChange = (event) => setState({ ...state, [event.target.name]: event.target.value })

  const _handleValidate = () => {
    // TODO : check values format before saving
    const alreadyExists = salesPoints.some(
      (salesPoint) =>
        salesPoint.name === state.name.toUpperCase() &&
        salesPoint.address.city === state.city.toUpperCase() &&
        salesPoint.address.zipcode === state.zipcode,
    )
    if (!alreadyExists) onValidate(state)
    else setAlert(true)
  }

  return (
    <DialogForm open={open} onValidate={_handleValidate} onClose={onClose} title="Ajouter un point de vente">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AlertForm
            openAlert={openAlert}
            onClose={() => {
              setAlert(false)
            }}
            text="Le point de vente existe déjà !"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name="distributor" value={distributorName} label="Rattachement au distributeur" disabled={true} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name="name" value={state.name} onChange={_handleChange} label="Nom du point de vente" />
        </Grid>
        <Grid item xs={12}>
          <TextField name="address" value={state.address} onChange={_handleChange} label="Adresse" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name="zipcode" value={state.zipcode} onChange={_handleChange} label="Code Postal" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name="city" value={state.city} onChange={_handleChange} label="Ville" />
        </Grid>
      </Grid>
      <Grid item xs={12} align="right">
        <WarningMessage text="N'oubliez pas d'effectuer également les actions dans Sparc" />
      </Grid>
    </DialogForm>
  )
}
