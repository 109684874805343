import { Box, Typography } from '@material-ui/core'
import React from 'react'
import API from '../../api/api'
import { allDistributorsQuery } from '../../api/queries/distributorQueries'
import Alert from '../../components/AlertSnackbar/index.jsx'
import ExportCard from '../../components/Card/ExportCard'
import TransferList from '../../components/TransferList/index.jsx'
import { UserContext } from '../../contexts/userContext'
import { ALERT_SEVERITY, FILE_TARGET, USER_ROLES } from '../../utils/constants'
import { sortArrayByValue } from '../../utils/functions'
import { exportExcel, exportTxt } from './functions'

export default class ExportScene extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      alert: { open: false, severity: ALERT_SEVERITY.warning, text: '' },
      distributors: [],
      isDownloading: { excel: false, ksiop: false },
      selectedDistributors: [],
      target: { excel: '', ksiop: '' },
    }
    this.type = { excel: 'excel', ksiop: 'ksiop' }
  }

  static contextType = UserContext

  async componentDidMount() {
    const user = this.context
    const result = await API.queryAPI(allDistributorsQuery(user.userLogin))
    if (result === null || result.user === null) return
    this.setState({ distributors: sortArrayByValue(result.user.distributors, 'name') })
  }

  async _handleExport(type) {
    if (!this.state.target[type]) return
    if (this.state.selectedDistributors.length === 0) {
      this.setState({ alert: { open: true, severity: ALERT_SEVERITY.warning, text: 'Aucun distributeur sélectionné' } })
      return
    }
    this.setState({ isDownloading: { ...this.state.isDownloading, [type]: true } })
    const { success, severity, message } =
      type === this.type.excel
        ? await exportExcel(this.state.target.excel, this.state.selectedDistributors)
        : await exportTxt(this.state.target.ksiop, this.state.selectedDistributors)
    this.setState({
      isDownloading: { ...this.state.isDownloading, [type]: false },
      alert: success ? this.state.alert : { open: true, severity, text: message },
    })
  }

  render() {
    const user = this.context
    const excelInputs = [
      { label: 'Export des volumes (avec les écarts)', value: FILE_TARGET.excelAll },
      { label: 'Export des écarts uniquement', value: FILE_TARGET.excelError },
      { label: 'Export des arrêts AML', value: FILE_TARGET.excelAmlStop },
      { label: 'Export des emails des distributeurs', value: FILE_TARGET.excelEmailsDistributors },
      { label: 'Export des PAD France Boissons', value: FILE_TARGET.excelFbPAD },
    ]
    const ksiopInputs = [
      { label: 'Fichier des AML et AMA', value: FILE_TARGET.ksiopAML },
      { label: 'Fichier PAD Indépendants', value: FILE_TARGET.ksiopPAD },
      { label: 'Fichier PAD France Boissons', value: FILE_TARGET.ksiopFbPAD },
    ]

    return (
      <>
        <Alert
          open={this.state.alert.open}
          severity={this.state.alert.severity}
          text={this.state.alert.text}
          onClose={() => this.setState({ alert: { ...this.state.alert, open: false } })}
        />
        <Box p={5}>
          <Typography variant="h5" color="primary">
            Sélectionner les données à exporter :
          </Typography>
          <ExportCard
            key="exportcard"
            inputs={excelInputs}
            isDownloading={this.state.isDownloading.excel}
            title="Export Excel"
            onChange={(target) => this.setState({ target: { ...this.state.target, excel: target } })}
            onValidate={() => this._handleExport(this.type.excel)}
          />
          {user.userRole === USER_ROLES.admin && (
            <ExportCard
              key="ksiopcard"
              inputs={ksiopInputs}
              isDownloading={this.state.isDownloading.ksiop}
              title="Export Cassiopae"
              onChange={(target) => this.setState({ target: { ...this.state.target, ksiop: target } })}
              onValidate={() => this._handleExport(this.type.ksiop)}
            />
          )}
          {(this.state.target.excel || this.state.target.ksiop) && this.state.distributors.length > 0 && (
            <TransferList
              itemsArray={this.state.distributors}
              onUpdate={(selected) => this.setState({ selectedDistributors: selected })}
            />
          )}
        </Box>
      </>
    )
  }
}
