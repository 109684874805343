import { Box, Button, Grid, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import API from '../../api/api'
import { updateFileStopAML, updateFilesVolumeBySalesPoint } from '../../api/mutations/fileMutations'
import ActionMenu from '../../components/ActionMenu'
import Alert from '../../components/AlertSnackbar/index.jsx'
import SumCard from '../../components/Card/SumCard'
import CustomizedCheckbox from '../../components/Form/CustomizedCheckbox'
import FileForm from '../../components/Form/FileForm'
import FilesTable from '../../components/Table/FilesTable'
import LoadingTable from '../../components/Table/LoadingTable'
import { ALERT_MESSAGE, ALERT_SEVERITY, DEFAULT_SUCCESS_ALERT, FILE_ACTIONS, SERVICES_ID } from '../../utils/constants'
import { getFilesTableData, getFormTitle, getNewState, getNewTotal, getNewValueState, getSumCards } from './functions'

export default class SalesPointZoom extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      alert: {
        open: false,
        message: '',
        severity: '',
      },
      salesPoint: {},
      openFile: false,
      selectedFile: {},
      formAction: null,
      formTitle: '',
      errors: {
        AML: false,
        AMA: false,
        PAD: false,
      },
      nextSalesPointId: null,
      isCompleted: false,
      totalSum: {
        AML: { amount: null, volume: null },
        AMA: { amount: null, volume: null },
        PAD: { amount: null, volume: null },
      },
      stopAML: null,
    }
  }

  async componentDidMount() {
    this.setState(await getNewState(this.props.match.params.id, this.props.location.state.salesPoints))
  }

  async componentDidUpdate() {
    // Case the user wants to access the next sales point
    if (this.state.salesPoint.id && parseInt(this.props.match.params.id) !== this.state.salesPoint.id)
      this.setState(await getNewState(this.props.match.params.id, this.props.location.state.salesPoints))
  }

  _handleMenu = async ({ action, alert }) => {
    if (Object.entries(alert).length > 0) {
      this.setState({ alert })
      return
    }
    this.setState({ formAction: action, formTitle: getFormTitle(action), openFile: true })
  }

  _handleSaveVolume = (alert, newFile) => {
    if (!newFile) {
      this.setState({ alert })
      return
    }
    this.setState({ alert, ...getNewValueState(this.state, newFile) })
  }

  _handleStopAML = async () => {
    const AMLSparcCode = this.state.salesPoint.files.find((file) => file.service.id === SERVICES_ID.AML)?.sparcCode
    if (!AMLSparcCode) {
      this.setState({ alert: { open: true, severity: ALERT_SEVERITY.error, message: ALERT_MESSAGE.error } })
      return
    }
    await API.queryAPI(updateFileStopAML(AMLSparcCode, !this.state.stopAML))
    this.setState({
      alert: DEFAULT_SUCCESS_ALERT,
      stopAML: !this.state.stopAML,
    })
  }

  _handleValidate = async (goToNext) => {
    if (this.state.isCompleted) {
      await API.queryAPI(updateFilesVolumeBySalesPoint(0, this.state.salesPoint.id))
      this.setState({
        alert: DEFAULT_SUCCESS_ALERT,
        isCompleted: false,
      })
    }
    if (goToNext) this._goToNextSalesPoint()
    else this.props.history.push(`/distributor/${this.props.location.state.id}`)
  }

  _goToNextSalesPoint = () => {
    if (!this.state.nextSalesPointId) this.props.history.push(`/distributor/${this.props.location.state.id}`)
    else
      this.props.history.push({
        pathname: `/sales-point/${this.state.nextSalesPointId}`,
        state: this.props.location.state,
      })
  }

  _renderFilesTable() {
    if (!this.state.salesPoint.files) return <LoadingTable />
    const { categorizedFiles, otherFiles } = getFilesTableData(this.state.salesPoint.files)
    return (
      <>
        {otherFiles.length > 0 && (
          <FilesTable
            key={`${this.state.salesPoint.id}-AMAPAD`}
            files={otherFiles}
            hasCategory={false}
            hideAmounts={this.state.errors}
            selectedFile={this.state.selectedFile}
            status={this.props.location.state.status?.name}
            title="AMA/PAD"
            onMenuClick={this._handleMenu}
            onSelectFile={(file) => this.setState({ selectedFile: file })}
            onSave={this._handleSaveVolume}
          />
        )}
        {categorizedFiles.length > 0 && (
          <>
            <FilesTable
              key={`${this.state.salesPoint.id}-AML`}
              files={categorizedFiles}
              hasCategory={true}
              hideAmounts={this.state.errors}
              selectedFile={this.state.selectedFile}
              status={this.props.location.state.status?.name}
              title="AML"
              onMenuClick={this._handleMenu}
              onSelectFile={(file) => this.setState({ selectedFile: file })}
              onSave={this._handleSaveVolume}
            />
            <Box display="flex" justifyContent="flex-end" mb={2}>
              <CustomizedCheckbox
                isCompleted={this.state.stopAML ?? categorizedFiles[0].stopAML}
                label="Cochez la case si vous voulez faire un arrêt de votre dossier au 31/12"
                onChange={this._handleStopAML}
              />
            </Box>
          </>
        )}
        {otherFiles.length === 0 && categorizedFiles.length === 0 && (
          <Box display="flex" justifyContent="flex-end">
            <ActionMenu
              items={['Ajouter un dossier']}
              onMenuClick={() => this._handleMenu({ action: FILE_ACTIONS.addFile, alert: {} })}
            />
          </Box>
        )}
      </>
    )
  }

  render() {
    const isCompleted = (files) => files.every((file) => file.volume !== null)

    return (
      <Grid container spacing={1}>
        <Alert
          open={this.state.alert.open}
          severity={this.state.alert.severity}
          text={this.state.alert.message}
          onClose={() => this.setState({ alert: { ...this.state.alert, open: false } })}
        />
        <Grid item xs={12} sm={6}>
          <Typography variant="h5">{this.state.salesPoint.name}</Typography>
          <Typography variant="body2">
            {this.state.salesPoint.address?.address}
            <br />
            {this.state.salesPoint.address?.zipcode} {this.state.salesPoint.address?.city}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {this.state.salesPoint.files && (
            <SumCard sums={getSumCards(this.state.salesPoint, this.state.totalSum, this.state.errors)} />
          )}
        </Grid>
        <Grid item xs={12}>
          {this._renderFilesTable()}
          <Box display="flex" justifyContent="flex-end" mb={2}>
            <CustomizedCheckbox
              isCompleted={this.state.isCompleted}
              label="Je déclare ce PDV complet"
              onChange={() => this.setState({ isCompleted: !this.state.isCompleted })}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end">
            {this.state.nextSalesPointId && ( // Handle case it's the last file of the sales point array
              <Button variant="contained" disabled={!isCompleted} onClick={() => this._handleValidate(true)}>
                Valider et passer au suivant
              </Button>
            )}
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              variant="contained"
              disabled={!isCompleted}
              onClick={() => this._handleValidate(false)}
              color="secondary"
            >
              Valider
            </Button>
          </Box>
        </Grid>

        {this.state.openFile && (
          <FileForm
            action={this.state.formAction}
            open={this.state.openFile}
            salesPoint={this.state.salesPoint}
            selectedFile={this.state.selectedFile}
            title={this.state.formTitle}
            onClose={() => this.setState({ selectedFile: {}, openFile: false })}
            onValidate={(updatedSalesPoint, serviceName) =>
              this.setState({
                salesPoint: updatedSalesPoint,
                selectedFile: {},
                openFile: false,
                totalSum: {
                  ...this.state.totalSum,
                  [serviceName]: {
                    amount: getNewTotal(updatedSalesPoint.files, serviceName, 'amount'),
                    volume: getNewTotal(updatedSalesPoint.files, serviceName, 'volume'),
                  },
                },
                alert: DEFAULT_SUCCESS_ALERT,
              })
            }
          />
        )}
      </Grid>
    )
  }
}

SalesPointZoom.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}
