export const formatNumber = (value) => {
  if (typeof value === 'string') {
    return value.replace(',', '.')
  }

  if (typeof value !== 'number') return
  return new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 2 }).format(value).replace(',', '.')
}

export const formatPrice = (value) => {
  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value)
}

export const formatDate = (value) => {
  const date = new Date(value)
  return date.toLocaleDateString('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit' })
}
