import { Box, Button } from '@material-ui/core'
import { useState } from 'react'
import CommonTextField from '../Form/TextFieldForm'

export default function Comments({ comments, onAddComment }) {
  const [newComment, setNewComment] = useState(undefined)
  function _handleChange(value) {
    setNewComment(value)
  }
  function _handleAddComment() {
    if (newComment.trim() !== '') {
      onAddComment(comment.defaultValue)
      setNewComment('')
    }
  }
  return (
    <>
      <Box style={{ fontWeight: 'bold' }}>Commentaires:</Box>
      {comments?.map((comment) => {
        return (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              fontSize: '0.9rem',
              color: '#9d9d9d',
              marginTop: '0.3em',
              fontStyle: 'italic',
              fontWeight: 'bold',
            }}
            key={`comments-${comment.id}`}
          >
            <Box style={{ display: 'flex', flexDirection: 'row', gap: '0.3em' }}>
              <Box>{comment.user.firstname}</Box>
              <Box>{comment.user.lastname}</Box>
              <Box>{new Date(Number(comment.date)).toLocaleString()}</Box>
            </Box>
            <Box>{comment.value}</Box>
          </Box>
        )
      })}
      <Box style={{ display: 'flex', flexDirection: 'row', gap: '0.3em', alignItems: 'center' }}>
        <Box>
          <CommonTextField
            name="comment"
            value={newComment}
            onChange={(e) => _handleChange(e.target.value)}
            label="Commentaire"
            multiLine
          />
        </Box>

        <Box>
          <Button onClick={_handleAddComment} variant="contained" color="primary">
            AJOUTER
          </Button>
        </Box>
      </Box>
    </>
  )
}
