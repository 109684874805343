import { Button, Grid, LinearProgress, Paper, TextField, Typography } from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import SettingsIcon from '@material-ui/icons/Settings'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import API from '../../api/api'
import { UserContext } from '../../contexts/userContext'
import { USER_ROLES } from '../../utils/constants'
import './Import.css'

export default class ImportScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentFiles: null,
      newFiles: [],
    }
  }

  static contextType = UserContext

  componentDidMount() {
    const user = this.context
    if (user.userRole !== USER_ROLES.admin) this.props.history.push('/')
    this._retrieveCurrentFiles()
  }

  async _retrieveCurrentFiles() {
    this.setState({
      currentFiles: {
        users: await API.restAPI('currentFiles?dir=users', 'GET'),
        files: await API.restAPI('currentFiles?dir=files', 'GET'),
        t123: await API.restAPI('currentFiles?dir=t123', 'GET'),
        emails: await API.restAPI('currentFiles?dir=emails', 'GET'),
      },
    })
  }

  _displayLastFile = (file) => {
    if (this.state.currentFiles === null) {
      return (
        <Grid item xs={8} style={{ margin: '15px 0px' }}>
          <LinearProgress />
        </Grid>
      )
    }

    const names = {
      users: 'utilisateurs',
      files: 'dossiers',
      t123: 'versements des trois premiers trimestres',
      emails: 'emails',
    }
    if (this.state.currentFiles[file]?.size === 0) {
      return (
        <Grid item xs={8} style={{ margin: '10px 0px' }}>
          Aucun fichier n&apos;a été téléchargé concernant les {names[file]}.
        </Grid>
      )
    }
    const date = moment(this.state.currentFiles[file]?.date).calendar(null, {
      sameDay: "[aujourd'hui à] HH:MM",
      lastDay: '[hier à] HH:MM',
      sameElse: '[le] DD MMMM YYYY [à] HH:MM',
    })

    return (
      <Grid item xs={8} style={{ margin: '10px 0px' }}>
        Le dernier fichier des {names[file]} a été téléchargé {date}.
        <br />
        Il contient {this.state.currentFiles[file]?.size} lignes
        <br />
        <br />
        Actuellement, la base de données contient {this.state.currentFiles[file]?.count}{' '}
        {file === 't123' ? 'distributeurs avec des montants T123 > 0' : names[file]}.
      </Grid>
    )
  }

  _onChangeHandler = (event, subject) => {
    let newFiles = this.state.newFiles
    newFiles.push({ file: event.target.files[0], subject: subject })
    this.setState({ newFiles: newFiles })
  }

  _uploadFiles = async () => {
    const data = new FormData()
    this.state.newFiles.forEach((file) => {
      data.append(file.subject, file.file)
    })
    await API.restAPI('upload', 'POST', data)
    this.setState({ currentFiles: null })
    this._retrieveCurrentFiles()
  }

  render() {
    return (
      <div className="importContainer">
        <Typography variant="h5" color="primary">
          Gestion des imports
        </Typography>
        <Grid
          container
          spacing={3}
          direction="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          style={{ height: '90%' }}
        >
          {/* // TODO: create a component for the 3 following cards */}
          <Grid item component={Paper} style={{ width: '100%', margin: '25px 0px' }}>
            <Typography variant="subtitle1">
              <b>Utilisateurs</b>
            </Typography>
            <Grid container direction="row">
              {this._displayLastFile('users')}
              <Grid item xs={4}>
                <TextField
                  style={{ marginLeft: '20px' }}
                  type="file"
                  name="file"
                  onChange={(event) => this._onChangeHandler(event, 'users')}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<SettingsIcon />}
                  onClick={() => API.restAPI('importUsers')}
                  style={{ marginLeft: '20px', marginTop: '20px' }}
                >
                  Lancer le traitement
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item component={Paper} style={{ width: '100%', margin: '25px 0px' }}>
            <Typography variant="subtitle1">
              <b>Dossiers</b>
            </Typography>
            <Grid container direction="row">
              {this._displayLastFile('files')}
              <Grid item xs={4}>
                <TextField
                  style={{ marginLeft: '20px' }}
                  type="file"
                  name="file"
                  onChange={(event) => this._onChangeHandler(event, 'files')}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<SettingsIcon />}
                  onClick={() => API.restAPI('importFiles')}
                  style={{ marginLeft: '20px', marginTop: '20px' }}
                >
                  Lancer le traitement
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item component={Paper} style={{ width: '100%', margin: '25px 0px' }}>
            <Typography variant="subtitle1">
              <b>Paiements des 3 premiers trimestres</b>
            </Typography>
            <Grid container direction="row">
              {this._displayLastFile('t123')}
              <Grid item xs={4}>
                <TextField
                  style={{ marginLeft: '20px' }}
                  type="file"
                  name="file"
                  onChange={(event) => this._onChangeHandler(event, 't123')}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<SettingsIcon />}
                  onClick={() => API.restAPI('importT123')}
                  style={{ marginLeft: '20px', marginTop: '20px' }}
                >
                  Lancer le traitement
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item component={Paper} style={{ width: '100%', margin: '25px 0px' }}>
            <Typography variant="subtitle1">
              <b>Emails</b>
            </Typography>
            <Grid container direction="row">
              {this._displayLastFile('emails')}
              <Grid item xs={4}>
                <TextField
                  style={{ marginLeft: '20px' }}
                  type="file"
                  name="file"
                  onChange={(event) => this._onChangeHandler(event, 'emails')}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<SettingsIcon />}
                  onClick={() => API.restAPI('importEmails')}
                  style={{ marginLeft: '20px', marginTop: '20px' }}
                >
                  Lancer le traitement
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="flex-end" style={{ width: '100%', margin: '25px 0px' }}>
            <Grid item>
              <Button variant="contained" color="primary" startIcon={<CloudUploadIcon />} onClick={this._uploadFiles}>
                Envoyer les fichiers
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

ImportScreen.propTypes = {
  history: PropTypes.object,
}
