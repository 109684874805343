import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import 'moment/locale/fr'
import MomentUtils from '@date-io/moment'
import React from 'react'

export default class DatePickerForm extends React.Component {
  render() {
    return (
      <MuiPickersUtilsProvider locale="fr" utils={MomentUtils}>
        <KeyboardDatePicker
          id={this.props.name}
          name={this.props.name}
          value={this.props.value}
          onChange={this.props.onChange}
          disableToolbar
          variant="inline"
          format="DD/MM/YYYY"
          margin="normal"
          label={this.props.label}
          fullWidth
          autoOk
          inputVariant="outlined"
          disabled={this.props.disabled ?? false}
          error={this.props.error ?? false}
          helperText={this.props.error ? 'Champ obligatoire' : ''}
        />
      </MuiPickersUtilsProvider>
    )
  }
}
