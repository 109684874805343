import { Box, Typography } from '@material-ui/core'
import React from 'react'
import './TableTitle.css'

export default class TableTitle extends React.Component {
  render() {
    return (
      <Box p={2} flexGrow={1}>
        <Typography variant="h6" className="table-title">
          {this.props.text}
        </Typography>
      </Box>
    )
  }
}
