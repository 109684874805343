import { Typography } from '@material-ui/core'

export default function GDPRScene() {
  return (
    <>
      <Typography title variant="h4" component="h4" gutterBottom>
        POLITIQUE DE CONFIDENTIALITE
      </Typography>
      <Typography paragraph style={{ marginTop: '40px' }}>
        HEINEKEN Entreprise, Société par Actions Simplifiée au capital de 130.784.350 euros dont le siège social est
        situé Immeuble H2O, 2 rue des Martinets - 92500 Rueil-Malmaison - immatriculée au RCS de Nanterre sous le N° 414
        842 062 édite la plateforme HECTOR (la « Plateforme »). HEINEKEN est attaché au respect de la vie privée de ses
        collaborateurs accédant à la Plateforme. La présente Politique de confidentialité a pour objet de permettre à
        chaque collaborateur de prendre connaissance des modalités de collecte et de traitement des données à caractère
        personnel sur la Plateforme et de ses droits en matière de respect de sa vie privée et de protection de ses
        données à caractère personnel. Dans le cadre des traitements de données à caractère mise en œuvre sur la
        Plateforme, HEINEKEN se conforme à la Loi n° 78-17 du 6 janvier 1978 relative à l&apos;informatique, aux
        fichiers et aux libertés (loi « Informatique et Libertés ») et au Règlement Général sur la Protection des
        Données N° 2016/679 du 27 avril 2016 (« RGPD ») (ensemble la « Règlementation sur la protection des données »)
        Dans le cadre de sa politique de respect de la vie privée des personnes, HEINEKEN a désigné un Délégué à la
        Protection des Données que vous pouvez contacter pour toute demande relative à la protection des données à
        caractère personnel à l’adresse suivante : DPO-HEINEKEN, 2 rue des Martinets, 92569 RUEIL MALMAISON cedex.
      </Typography>
      <Typography subtitle="h2" style={{ marginTop: '40px', fontWeight: 'bold' }}>
        ARTICLE 1 - Pour quelles raisons pouvons-nous traiter vos données dans le cadre de votre navigation sur la
        Plateforme ?
      </Typography>
      <Typography paragraph style={{ marginTop: '40px' }}>
        Au cours de votre navigation sur la Plateforme, nous sommes susceptibles de collecter des données vous
        concernant. Aucune des données ne sera collectée à votre insu. Nous ne collectons vos données que pour les
        finalités déterminées, explicites et légitimes suivantes :
        <br />
        &emsp;• Gestion des accès à la Plateforme
        <br />
        &emsp;• Maintenance et optimisation de la Plateforme en vue de vérifier/améliorer la qualité de service, la
        disponibilité et la performance du service, solutionner les éventuels problèmes ou anomalies de fonctionnement,
        et sécuriser la Plateforme contre la fraude.
      </Typography>
      <Typography subtitle="h2" style={{ marginTop: '40px', fontWeight: 'bold' }}>
        ARTICLE 2 - Avec qui partageons-nous vos données ?
      </Typography>
      <Typography paragraph style={{ marginTop: '40px' }}>
        Nous sommes amenés à partager les données vous concernant avec des tiers sous-traitants pour nous aider à
        exécuter et fournir nos services. Nous nous assurons que nos sous-traitants présentent les garanties suffisantes
        quant aux mesures techniques et organisationnelles mises en œuvre de manière à ce que l’usage des données soit
        effectué conformément à la Règlementation sur la protection des données.
      </Typography>
      <Typography subtitle="h2" style={{ marginTop: '40px', fontWeight: 'bold' }}>
        ARTICLE 3 - Où sont localisées vos données ?
      </Typography>
      <Typography paragraph style={{ marginTop: '40px' }}>
        Les données peuvent être localisées dans un pays de l’Union européenne mais aussi en dehors de l’Union
        Européenne. Dans ce dernier cas, nous nous assurons que les données se situent dans un pays hors UE assure un
        niveau de protection adéquat concernant le traitement des données en conformité avec la Règlementation sur la
        protection des données. A défaut, nous prenons des garanties appropriées de manière à ce que le niveau de
        protection garanti par la Règlementation sur la protection des données.
      </Typography>
      <Typography subtitle="h2" style={{ marginTop: '40px', fontWeight: 'bold' }}>
        ARTICLE 4 - Quelle est la durée de conservation de vos données personnelles ?
      </Typography>
      <Typography paragraph style={{ marginTop: '40px' }}>
        Vos données sont collectées et traitées dans le cadre des services fournis sur la Plateforme et conformément à
        la présente Politique. Nous conservons vos données pendant une durée n&apos;excédant pas celle nécessaire au
        regard des finalités pour lesquelles elles sont traitées. Ainsi vos données sont généralement conservées pour
        une durée de 6 mois sur la Plateforme.
      </Typography>
      <Typography subtitle="h2" style={{ marginTop: '40px', fontWeight: 'bold' }}>
        ARTICLE 5 - Comment sécurisons-nous vos données ?
      </Typography>
      <Typography paragraph style={{ marginTop: '40px' }}>
        HEINEKEN s&apos;engage à prendre toutes les mesures techniques et organisationnelles nécessaires afin de
        garantir un niveau de sécurité, d&apos;intégrité et de confidentialité adapté au risque compte tenu notamment de
        l&apos;état des connaissances, des coûts de mise en œuvre et de la nature, de la portée, du contexte et des
        finalités du traitement pour protéger vos données contre le vol, la destruction, l’altération, la perte
        accidentelle, la diffusion ou l&apos;accès non autorisé.
      </Typography>
      <Typography subtitle="h2" style={{ marginTop: '40px', fontWeight: 'bold' }}>
        ARTICLE 6 - Quels sont vos droits ?
      </Typography>
      <Typography paragraph style={{ marginTop: '40px' }}>
        Vous disposez de droits sur vos données à caractère personnel. Conformément à la réglementation en matière de
        protection des données à caractère personnel et après avoir justifié de votre identité, vous bénéficiez, dans
        les conditions prévues aux articles 15 à 22 du RGPD :
        <br />
        &emsp;• d&apos;un droit d&apos;accès : l’internaute peut obtenir communication des données le concernant;
        <br />
        &emsp;• d&apos;un droit de rectification : en cas d&apos;inexactitude de ces informations, l’internaute peut
        exiger qu&apos;elles soient rectifiées ou complétées;
        <br />
        &emsp;• d&apos;un droit d’effacement : l’internaute peut exiger que ses données soient effacées, équivalent pour
        tout inscrit à un espace privé, à la fermeture de son compte.
        <br />
        &emsp;• d’un droit d’opposition : l’internaute peut s’opposer au traitement de ses données à des fins de
        prospection commerciale ;
        <br />
        &emsp;• d’un droit de limitation : l’internaute peut obtenir la limitation du traitement de ses données ;
        <br />
        &emsp;• d’un droit de portabilité : l’internaute peut demander de recevoir ses données personnelles qu’il a
        fournies dans un format structuré, couramment utilisé et lisible par machine, dans certaines circonstances, ou
        demander qu’elles soient transmises à un autre responsable de traitement si cela est techniquement possible.
        Vous pouvez contactez nos Services afin d’exercer vos droits à l’adresse électronique suivante :
        dpoheinekenentreprise@heineken.fr en joignant à votre demande une copie d’un titre d’identité.
      </Typography>
      <Typography subtitle="h2" style={{ marginTop: '40px', fontWeight: 'bold' }}>
        ARTICLE 7 - Comment contacter la Commission Nationale de l’Informatique et des Libertés ?
      </Typography>
      <Typography paragraph style={{ marginTop: '40px' }}>
        HEINEKEN vous rappelle que vous pouvez contacter la CNIL directement sur le site internet
        https://www.cnil.fr/fr/agir ou par courrier à l’adresse suivante : Commission Nationale de l&apos;Informatique
        et des Libertés, 3 Place de Fontenoy - TSA 80715, 75334 PARIS CEDEX 07
      </Typography>
    </>
  )
}
