import React from 'react'
import { Typography } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/WarningRounded'

export default class WarningMessage extends React.Component {
  render() {
    return (
      <Typography variant="body2" paragraph>
        <WarningIcon fontSize="small" color="secondary" /> {this.props.text}
      </Typography>
    )
  }
}
