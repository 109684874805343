export const getDisabled = (disabled, keys) => {
  const updatedDisabled = {}
  Object.keys(disabled).forEach((key) => {
    if (!keys.includes(key)) updatedDisabled[key] = true
  })
  return updatedDisabled
}

function calculateRawPaybackHT(file) {
  return (file.volume - (file.free_HE ?? 0) - (file.free_FB ?? 0)) * ((file.discount_HE ?? 0) + file.discount_FB ?? 0)
}

export const calculatePaybackHT = (file) => {
  const paybackHT = calculateRawPaybackHT(file)
  return paybackHT.toFixed(2) ?? 0
}

export const calculatePaybackTTC = (file) => {
  const paybackHT = calculateRawPaybackHT(file)
  const paybackTTC = paybackHT * (1 + file.tax_rate / 100)
  return paybackTTC.toFixed(2) ?? 0
}
