import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react'
import { Box, Grid, Paper } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import API from '../../../api/api'
import { userQuery } from '../../../api/queries/userQueries'
import { UserContext } from '../../../contexts/userContext.jsx'
import routes from '../../../routes.js'
import { apiScopeRequest } from '../../../services/msal'
import { permissions } from '../../../utils/role.js'
import ErrorContent from '../../ErrorContent'
import SideBar from '../../SideBar'
import WelcomeBox from '../../WelcomeBox'

HomeLayout.propTypes = {
  children: PropTypes.element,
}

export default function HomeLayout() {
  const { accounts, inProgress, instance } = useMsal()
  const [error, setError] = useState({ status: false, message: '' })
  const [user, setUser] = useState({})

  useEffect(() => {
    if (inProgress === InteractionStatus.None && accounts.length > 0) {
      const request = {
        ...apiScopeRequest,
        account: accounts[0],
      }
      instance
        .acquireTokenSilent({
          ...apiScopeRequest,
          account: accounts[0],
        })
        .then(async (accessTokenResponse) => {
          localStorage.setItem('token', accessTokenResponse.accessToken)
          if (accounts[0]?.username) {
            API.queryAPI(userQuery(accounts[0]?.username))
              .then((userResult) => {
                setUser(userResult.user ?? null)
              })
              .catch((errorMessage) => setError({ status: true, message: errorMessage }))
          }
        })
        .catch(async (e) => {
          if (e instanceof InteractionRequiredAuthError) {
            await instance.acquireTokenRedirect(request)
          }
          throw e
        })
    }
  }, [accounts, inProgress, instance])

  return (
    <>
      <AuthenticatedTemplate>
        {!error.status && Object.entries(user).length > 0 ? (
          <UserContext.Provider value={{ userLogin: user.login, userRole: user.role.id, userId: user.id }}>
            <Grid container style={{ minHeight: '110vh' }}>
              <Grid item sm={2} component={Paper} style={{ padding: '0px' }}>
                <SideBar
                  name={user.firstname}
                  canDisplayIndep={user.region === null || permissions['/'].includes(user.region?.id)}
                />
              </Grid>
              <Grid item xs={12} sm={10}>
                <Box m={2} style={{ height: '100%' }}>
                  <main>
                    <Switch>
                      {user.region === null || permissions['/'].includes(user.region?.id)
                        ? routes.map((route) => (
                            <Route
                              exact
                              key={route.path}
                              path={route.path}
                              render={(props) => <route.component {...props} />}
                            />
                          ))
                        : routes.map((route) => {
                            if (route.path !== '/') {
                              return (
                                <Route
                                  exact
                                  key={route.path}
                                  path={route.path}
                                  render={(props) => <route.component {...props} />}
                                />
                              )
                            }
                          })}
                      <Redirect to="/fb" />,
                    </Switch>
                  </main>
                </Box>
              </Grid>
            </Grid>
          </UserContext.Provider>
        ) : (
          (error.status || user === null) && <ErrorContent message={error.message} />
        )}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <WelcomeBox />
      </UnauthenticatedTemplate>
    </>
  )
}
