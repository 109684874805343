import API from '../../api/api'
import { FbPadFileQuery } from '../../api/queries/fbFileQueries'
import { FB_PAD_ACTIONS, SERVICES } from '../../utils/constants'
import { formatPrice } from '../../utils/format'
import { getAMLStatusError, getServiceStatusError } from '../../utils/functions'

export const getNewState = async (currentId, files) => {
  const fileIndex = files.findIndex((file) => file.id === parseInt(currentId))
  const result = await API.queryAPI(FbPadFileQuery(currentId))
  return {
    file: result.fbPadFile,
    nextFileId: files[fileIndex + 1]?.id ?? null,
    isCompleted: false,
  }
}

export const getFormTitle = (action) => {
  if (action === FB_PAD_ACTIONS.update) return 'Modifier une marque existante'
}

export const getServicesStatusErrors = (salesPoint) => {
  return {
    AML: getAMLStatusError(salesPoint),
    AMA: getServiceStatusError(SERVICES.AMA, salesPoint),
    PAD: getServiceStatusError(SERVICES.PADLeader, salesPoint) || getServiceStatusError(SERVICES.PADHKOnly, salesPoint),
  }
}

export const getSumCards = (file) => {
  const total = file.fbPadLines.reduce((acc, cur) => {
    const paybackHT =
      cur.lineStatus <= 2 ? (cur.volume - cur.free_HE - cur.free_FB) * (cur.discount_HE + cur.discount_FB) : 0
    return acc + paybackHT * (1 + cur.tax_rate / 100)
  }, 0)

  return [
    { title: "Montant de l'échéance à l'année", value: formatPrice(file.annualAmount), error: false },
    {
      title: 'Ristourne totale du PAD',
      value: formatPrice(total),
      error: false,
    },
    {
      title: 'Solde',
      value: formatPrice(file.annualAmount - total),
      error: false,
    },
  ]
}

export const getFilesTableData = (files) => {
  const otherFiles = files.filter((file) => file.service.substring(0, 3) !== SERVICES.AML)

  return { categorizedFiles: [], otherFiles }
}

export const getNewTotal = (files, service, value) =>
  files
    .filter((file) => file.service.name.substring(0, 3) === service)
    .reduce((prev, current) => prev + current[value], 0)

export const getNewValueState = (currentState, newFile) => {
  const fileIndex = currentState.salesPoint.files.findIndex((file) => file.id === newFile.id)
  currentState.salesPoint.files[fileIndex] = newFile
  return {
    alert,
    salesPoint: {
      ...currentState.salesPoint,
      files: currentState.salesPoint.files,
    },
    totalSum: {
      ...currentState.totalSum,
      [newFile.service.name.substring(0, 3)]: {
        amount: getNewTotal(currentState.salesPoint.files, newFile.service.name.substring(0, 3), 'amount'),
        volume: getNewTotal(currentState.salesPoint.files, newFile.service.name.substring(0, 3), 'volume'),
      },
    },
  }
}

export const calculateDuration = (startingDate, endingDate, refYear) => {
  if (startingDate.getFullYear() > refYear || endingDate.getFullYear() < refYear) {
    return 0
  }

  const refYearStart = new Date(refYear, 0, 1)
  const refYearEnd = new Date(refYear, 11, 31)

  const startingDateLimitee = startingDate < refYearStart ? refYearStart : startingDate
  const endingDateLimitee = endingDate > refYearEnd ? refYearEnd : endingDate

  return endingDateLimitee.getMonth() - startingDateLimitee.getMonth() + 1
}
