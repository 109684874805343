import DistribZoom from './DistribZoom/DistribZoom'
import ExportScene from './Export/Export'
import FbFileZoom from './FbFileZoom/FbFileZoom'
import FbZoom from './FbZoom/FbZoom'
import GDPRScene from './GDPR/GDPR'
import Home from './Home/Home'
import ImportScene from './Import/Import'
import SalesPointZoom from './SalesPointZoom/SalesPointZoom'

export default {
  Home,
  DistribZoom,
  SalesPointZoom,
  ExportScene,
  ImportScene,
  GDPRScene,
  FbZoom,
  FbFileZoom,
}
