import { Collapse, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import MuiAlert from '@material-ui/lab/Alert'
import React from 'react'
import { ALERT_SEVERITY } from '../../../utils/constants'

export default class AlertForm extends React.Component {
  render() {
    return (
      <Collapse in={this.props.openAlert}>
        <MuiAlert
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={this.props.onClose}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity={ALERT_SEVERITY.error}
        >
          {this.props.text}
        </MuiAlert>
      </Collapse>
    )
  }
}
