import { Box, Button, Grid, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import API from '../../../api/api'
import { updateDistributorStatus } from '../../../api/mutations/distributorMutations'
import { UserContext } from '../../../contexts/userContext'
import { DISTRIBUTOR_STATUS, USER_ROLES } from '../../../utils/constants'

DistributorReportingButton.propTypes = {
  areVolumesCompleted: PropTypes.bool,
  distributorId: PropTypes.number,
  status: PropTypes.number,
  onUpdate: PropTypes.func.isRequired,
}

export default function DistributorReportingButton({ areVolumesCompleted, distributorId, status, onUpdate }) {
  const { userRole } = useContext(UserContext)

  async function _reOpenReporting() {
    if (userRole !== USER_ROLES.admin) return
    const result = await API.queryAPI(updateDistributorStatus(distributorId, DISTRIBUTOR_STATUS.toComplete))
    onUpdate(result.updateDistributorStatus.status)
  }

  async function _confirmReporting() {
    const result = await API.queryAPI(updateDistributorStatus(distributorId, DISTRIBUTOR_STATUS.validated))
    onUpdate(result.updateDistributorStatus.status)
  }

  async function _validateCollection() {
    const result = await API.queryAPI(updateDistributorStatus(distributorId, DISTRIBUTOR_STATUS.toValidate))
    onUpdate(result.updateDistributorStatus.status)
  }

  return (
    <Box display="flex" justifyContent="center">
      {status === DISTRIBUTOR_STATUS.validated && (
        <Typography variant="overline">La collecte de ce distributeur est validée</Typography>
      )}
      {status === DISTRIBUTOR_STATUS.toValidate && userRole === USER_ROLES.admin && (
        // TODO : use flex box to have the same buttons
        <Grid container justifyContent="space-around">
          <Grid item xs={5}>
            <Button variant="contained" onClick={_reOpenReporting} fullWidth color="secondary">
              Ré-ouvrir la collecte
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Button variant="contained" onClick={_confirmReporting} fullWidth color="primary">
              Valider <br />
              la collecte
            </Button>
          </Grid>
        </Grid>
      )}
      {status === DISTRIBUTOR_STATUS.toValidate && userRole !== USER_ROLES.admin && (
        <Typography variant="overline">La collecte de ce distributeur est en attente de validation</Typography>
      )}
      {(status === DISTRIBUTOR_STATUS.toComplete || status === DISTRIBUTOR_STATUS.completed) && (
        <Button variant="contained" onClick={_validateCollection} disabled={!areVolumesCompleted} color="primary">
          Déclarer la collecte de ce distributeur terminée
        </Button>
      )}
    </Box>
  )
}
