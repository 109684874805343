import { file } from '../common'

export const distributorQuery = (distributorId) => {
  return `{
    distributor(id: ${distributorId}) {
      id
      name
      payedAmount
      email
      totalAMLVolume
      totalAMAVolume
      totalPADLeaderVolume
      totalPADHKOnlyVolume
      totalAMLAmount
      totalAMAAmount
      totalPADLeaderAmount
      totalPADHKOnlyAmount
      status {
        id
        name
      }
      salesPoints {
        id
        name
        AMLVolume
        AMAVolume
        PADHKOnlyVolume
        PADLeaderVolume
        AMLAmount
        AMAAmount
        PADHKOnlyAmount
        PADLeaderAmount
        PADHKOnlyDistributorAmount
        address {
          address
          city
          zipcode
        }
        error {
          id
          name
        }
        files {
          ${file}
        }
      }
    }
  }`
}

export const distributorsByUserQuery = (userId) => {
  return `{
    user(login: "${userId}") {
      region {
        id
      }
      manager {
        id
      }
      commercials (includeManagers: true) {
        id
        firstname
        lastname
        role {
          id
        }
        manager {
          id
        }
    }
      distributors {
        id
        name
        status {
          id
          name
        }
        fbDistribCode
        payedAmount
        tangramCode
        totalAMLVolume
        totalAMAVolume
        totalPADLeaderVolume
        totalPADHKOnlyVolume
        totalAMLAmount
        totalAMAAmount
        totalPADLeaderAmount
        totalPADHKOnlyAmount
        fbPadFiles {
          id
          service
          status
        }
        salesPoints {
          id
          region {
            id
            name
          }
        }
      }
    }
  }`
}

export const allDistributorsQuery = (userId) => {
  return `{
    user(login: "${userId}") {
      distributors{
        id
        name
      }
    }
  }`
}

export const ksiopExportDistributor = (distributorId) => {
  return `distributor(id: ${distributorId}) {
    salesPoints {
      files {
        cassiopaeCode
        volume
        product {
          code
        }
        service {
          name
        }
      }
    }
  }`
}

export const excelExportDistributor = (distributorId) => {
  return `distributor(id: ${distributorId}) {
    name
    payedAmount
    tangramCode
    email
    status {
      name
    }
    salesPoints {
      name
      address {
        address
        city
        zipcode
      }
      error {
        name
      }
      files {
        sparcCode
        cassiopaeCode
        discount
        volume
        startingDate
        endingDate
        contentious {
          id
        }
        contentiousDate
        objectiveVolume
        objectiveAmount
        service {
          name
        }
        product {
          name
          code
          category {
            name
          }
        }
        error {
          name
        }
        stopAML
      }
    }
  }`
}

export const fbDistributorQuery = (distributorId) => {
  return `{
    distributor(id: ${distributorId}) {
      id
      name
      payedAmount
      email
      totalPADLeaderVolume
      totalPADHKOnlyVolume
      totalPADLeaderAmount
      totalPADHKOnlyAmount
      status {
        id
        name
      }
      fbPadFiles{
        id
        cassiopaeCode
        sparcCode
        totemCode
        nbSalespoint
        salespointName
        service
        status
        annualAmount
        error {
          name
        }
        fbPadLines {
          discount_HE
          discount_FB
          free_HE
          free_FB
          volume
          product {
            name
          }
          error {
            name
          }
          tax_rate
          lineStatus
          unit
        }
      }
    }
  }`
}

export const excelExportFbDistributorQuery = (distributorId) => {
  return `
    distributor(id: ${distributorId}) {
      id
      name
      payedAmount
      email
      status {
        id
        name
      }
      fbPadFiles{
        id
        cassiopaeCode
        sparcCode
        totemCode
        nbSalespoint
        salespointName
        service
        status
        annualAmount
        error {
          name
        }
        fbPadLines {
          id
          discount_HE
          discount_FB
          free_HE
          free_FB
          volume
          product {
            name
          }
          error {
            name
          }
          tax_rate
          unit
          lineStatus
          replaceLine {
            id
            discount_HE
            discount_FB
            free_HE
            free_FB
            volume
            lineStatus
          }
        }
      }
    }`
}
