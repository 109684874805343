import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'

export default class DialogForm extends React.Component {
  render() {
    return (
      <Dialog
        fullWidth
        maxWidth="md"
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent>{this.props.children}</DialogContent>
        <DialogActions>
          <Button onClick={this.props.onValidate} variant="contained" type="submit">
            Ajouter
          </Button>
          <Button onClick={this.props.onClose}>Annuler</Button>
        </DialogActions>
      </Dialog>
    )
  }
}
