import { Box, Card, CardContent, CardHeader, Typography } from '@material-ui/core'
import React from 'react'
import './ServiceInfoCard.css'

export default class ServiceInfoCard extends React.Component {
  render() {
    return (
      <Card style={{ width: '160px', height: '100%' }}>
        <CardHeader title={this.props.title} disableTypography className={`card-title ${this.props.className}-title`} />
        <CardContent>
          <Box>
            <Typography variant="overline" color="textSecondary">
              Volume (HL)
            </Typography>
          </Box>
          <Box fontWeight="fontWeightBold">{this.props.volume}</Box>
          <Box>
            <Typography variant="overline" color="textSecondary">
              Montants (€)
            </Typography>
          </Box>
          <Box fontWeight="fontWeightBold">{this.props.amount}</Box>
        </CardContent>
      </Card>
    )
  }
}
