import { utils, writeFile } from 'xlsx'

export function downloadBlob(blob, filename) {
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = filename || 'download'
  document.body.appendChild(a)
  a.click()
}

export function downloadExcel(dataArray, filename) {
  const workbook = utils.book_new()
  const worksheet = utils.json_to_sheet(dataArray)
  // Set column size depending on data length
  worksheet['!cols'] = Object.entries(dataArray[0]).map((data) => ({
    width: data[1]?.length ? (data[0].length > data[1].length ? data[0].length : data[1].length) : data[0].length,
  }))
  utils.book_append_sheet(workbook, worksheet, 'Data')
  writeFile(workbook, filename)
}
