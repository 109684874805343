import { Box, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { ALERT_MESSAGE } from '../../utils/constants'

ErrorContent.propTypes = {
  message: PropTypes.string,
}
ErrorContent.defaultProps = {
  message: ALERT_MESSAGE.error,
}

export default function ErrorContent({ message }) {
  return (
    <Box mt={1}>
      <Box color="primary" display="flex" justifyContent="center" alignItems="center">
        <Typography variant="h5" color="primary">
          {ALERT_MESSAGE.error}
        </Typography>
      </Box>
      {message !== ALERT_MESSAGE.error && (
        <Box color="primary" display="flex" justifyContent="center" alignItems="center">
          <Typography variant="body2">{message}</Typography>
        </Box>
      )}
    </Box>
  )
}
