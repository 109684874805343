import { ALERT_SEVERITY, CONTENTIOUS_STATUS, FILE_ACTIONS, MILESTONES, SERVICES } from '../../../utils/constants'
import { formatDate, formatNumber } from '../../../utils/format'

export const handleMenuClick = (action, selectedFile) => {
  if (Object.keys(selectedFile).length === 0 && action !== FILE_ACTIONS.addFile)
    return {
      action,
      alert: {
        severity: ALERT_SEVERITY.warning,
        message: 'Veuillez selectionner un dossier',
        open: true,
      },
    }

  if (
    (action === FILE_ACTIONS.updateDiscount || action === FILE_ACTIONS.updateDates) &&
    selectedFile.service.name !== SERVICES.AML
  )
    return {
      action,
      alert: {
        severity: ALERT_SEVERITY.warning,
        message:
          action === FILE_ACTIONS.updateDiscount
            ? '€/HL non modifiable sur un PAD ou une AMA (évol. de gamme)'
            : 'Dates non modifiable sur un PAD ou une AMA',
        open: true,
      },
    }

  return { action, alert: {} }
}

export const getFilesData = (files, hasCategory, hideAmounts) => {
  return files.map((file) => {
    let endingDate = file.endingDate
    if (file.contentiousDate !== null) endingDate = file.contentiousDate
    const date1 = new Date(file.startingDate)
    const date2 = new Date(endingDate)
    const years = date2.getFullYear() - date1.getFullYear()
    const months = years * 12 + (date2.getMonth() - date1.getMonth()) + 1
    const amount = !hasCategory ? formatNumber(file.volume * file.discount) ?? 0 : formatNumber(file.amount)
    return {
      id: file.id,
      type: hasCategory ? file.type : file.service.name.substring(0, 3),
      product: hasCategory ? '' : file.product.name.replace('HNK -', ''),
      code: file.sparcCode || '',
      discount: file.discount,
      distributorDiscount: file.discountDistrib ?? 'N/A',
      volume: formatNumber(file.volume) ?? '',
      amount: hideAmounts[file.service.name.substring(0, 3)] ? '-' : amount,
      months: months,
      dates: `${formatDate(file.startingDate)} ${formatDate(endingDate)}`,
      previousVolume: '',
      objectiveVolume: file.objectiveVolume,
      objectiveAmount: file.objectiveAmount,
      error: file.error,
      contentiousDate: file.contentiousDate,
      monthsTooltip:
        file.contentious?.id === CONTENTIOUS_STATUS.cont
          ? file.contentious.name
          : MILESTONES.includes(file.milestone?.code)
          ? "Dossier clos dans l'année"
          : '',
      files: hasCategory ? file.files : null,
    }
  })
}

export const checkFormat = (value) => {
  const regex = /^[\d\s]+,?[\d]*$/
  if (!regex.test(value)) {
    return { severity: ALERT_SEVERITY.warning, message: 'Mauvais format de volume', open: true }
  }
}
export function getCurrentFileFromProps(files, hasCategory, id) {
  const arrayFiles = hasCategory ? files.flatMap((category) => category.files) : files
  const currentFile = arrayFiles.find((item) => item.id === id)
  return currentFile
}
export function handleEnter(e, nextInput) {
  if (e.key === 'Enter' && nextInput) nextInput.focus()
}
