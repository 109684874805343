import {
  LinearProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
} from '@material-ui/core'
import React from 'react'

export default class LoadingTable extends React.Component {
  render() {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <LinearProgress />
              </TableCell>
              <TableCell>
                <LinearProgress />
              </TableCell>
              <TableCell>
                <LinearProgress />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <LinearProgress />
              </TableCell>
              <TableCell>
                <LinearProgress />
              </TableCell>
              <TableCell>
                <LinearProgress />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <LinearProgress />
              </TableCell>
              <TableCell>
                <LinearProgress />
              </TableCell>
              <TableCell>
                <LinearProgress />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}
