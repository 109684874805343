import { ALERT_SEVERITY, FB_PAD_ACTIONS, OTHER_PRODUCT_CATEGORY_ID } from '../../../utils/constants'
import { formatNumber } from '../../../utils/format'

export const handleMenuClick = (action, selectedFile) => {
  if (Object.keys(selectedFile).length === 0 && action !== FB_PAD_ACTIONS.addFile)
    return {
      action,
      alert: {
        severity: ALERT_SEVERITY.warning,
        message: 'Veuillez selectionner un dossier',
        open: true,
      },
    }

  if (
    (action === FB_PAD_ACTIONS.validateError || action === FB_PAD_ACTIONS.declineError) &&
    selectedFile.lineStatus !== 2
  )
    return {
      action,
      alert: {
        severity: ALERT_SEVERITY.warning,
        message: 'Veuillez selectionner un écart',
        open: true,
      },
    }
  return { action, alert: {} }
}

export const getFilesData = (files) => {
  let totalVolume = 0
  const fileData = files.map((file) => {
    const paybackHT = (file.volume - file.free_HE - file.free_FB) * (file.discount_HE + file.discount_FB)
    totalVolume += file.product.category.id !== OTHER_PRODUCT_CATEGORY_ID ? file.volume : 0
    return {
      id: file.id,
      type: file.product.category.name,
      product: file.product.name.replace('HNK -', ''),
      volume: formatNumber(file.volume) ?? '',
      freeHE: formatNumber(file.free_HE) ?? 0,
      freeFB: formatNumber(file.free_FB) ?? 0,
      discountHE: formatNumber(file.discount_HE) ?? 0,
      discountFB: formatNumber(file.discount_FB) ?? 0,
      paybackHT: formatNumber(paybackHT),
      taxRate: file.tax_rate,
      paybackTTC: formatNumber(paybackHT * (1 + file.tax_rate / 100)) ?? 0,
      units: file.unit,
      lineStatus: file.lineStatus,
      replaceLine: file.replaceLine?.id ?? null,
    }
  })
  fileData.push({
    id: null,
    type: 'TOTAL BIÈRES',
    product: '',
    volume: formatNumber(totalVolume) ?? '',
    freeHE: '',
    freeFB: '',
    discountHE: '',
    discountFB: '',
    paybackHT: '',
    taxRate: '',
    paybackTTC: '',
    units: '',
    lineStatus: '',
    replaceLine: null,
  })
  return fileData
}

export const checkFormat = (value) => {
  const regex = /^[\d\s]+,?[\d]*$/
  if (!regex.test(value)) {
    return { severity: ALERT_SEVERITY.warning, message: 'Mauvais format de volume', open: true }
  }
}
export function getCurrentFileFromProps(files, hasCategory, id) {
  const arrayFiles = hasCategory ? files.flatMap((category) => category.files) : files
  const currentFile = arrayFiles.find((item) => item.id === id)
  return currentFile
}
export function handleEnter(e, nextInput) {
  if (e.key === 'Enter' && nextInput) nextInput.focus()
}
