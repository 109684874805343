import { Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import ActionMenu from '../../ActionMenu'
import Switch from '../../Form/CommonSwitch'
import TableTitle from '../../Table/TableTitle'

ActionTableLayout.propTypes = {
  children: PropTypes.element,
  display: PropTypes.bool,
  menuItems: PropTypes.array,
  switchLabels: PropTypes.array,
  title: PropTypes.string,
  onChange: PropTypes.func,
  onMenuClick: PropTypes.func,
}

export default function ActionTableLayout({
  children,
  display,
  menuItems,
  switchLabels,
  title,
  onChange,
  onMenuClick,
  disableButton,
}) {
  return (
    <>
      <Box display="flex">
        <TableTitle text={title} />
        {menuItems && <ActionMenu items={menuItems} onMenuClick={onMenuClick} disable={disableButton} />}
      </Box>
      {switchLabels && (
        <Box display="flex" justifyContent="center">
          <Switch secondCase={display ? switchLabels[0] : switchLabels[1]} checked={display} onChange={onChange} />
        </Box>
      )}
      {children}
    </>
  )
}
