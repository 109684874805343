import { Box, Button } from '@material-ui/core'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

const ITEM_HEIGHT = 48

ActionMenu.propTypes = {
  items: PropTypes.array.isRequired,
  onMenuClick: PropTypes.func.isRequired,
}

export default function ActionMenu({ items, onMenuClick, disable = false }) {
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchor] = useState(null)

  const _handleClick = (event) => {
    setOpen(true)
    setAnchor(event.currentTarget)
  }

  const _handleClose = () => {
    setOpen(false)
    setAnchor(null)
  }

  const _handleSelect = (action) => {
    onMenuClick(action)
    _handleClose()
  }

  return (
    <>
      <Box m={2}>
        <Button variant="contained" onClick={_handleClick} color="primary" disabled={disable}>
          Déclarer un écart
        </Button>
      </Box>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={_handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '25ch',
          },
        }}
      >
        {items.map((item, index) => (
          <MenuItem key={index} onClick={() => _handleSelect(index)}>
            {item}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
