export const file = `
  id
  amount
  volume
  objectiveAmount
  objectiveVolume
  cassiopaeCode
  sparcCode
  startingDate
  endingDate
  discount
  discountDistrib
  state
  contentiousDate
  service {
    id
    code
    name
  }
  product {
    id
    code
    name
    category {
      id
      name
    }
  }
  contentious {
    id
    code
    name
  }
  milestone {
  code
  name
  }
  error {
    id
    name
  }
  stopAML`
