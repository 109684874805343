import { Switch } from '@material-ui/core'
import React from 'react'

export default class CommonSwitch extends React.Component {
  render() {
    return (
      <div>
        {this.props.firstCase}
        <Switch
          checked={this.props.checked}
          onChange={() => this.props.onChange()}
          name="switchAllFiles"
          inputProps={{ 'aria-label': 'switch all files' }}
          color="default"
        />
        {this.props.secondCase}
      </div>
    )
  }
}
