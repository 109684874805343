import views from './scenes'

const Home = {
  component: views.Home,
  path: '/',
}

const CollectFB = {
  component: views.Home,
  path: '/fb',
}
const DistribZoom = {
  component: views.DistribZoom,
  path: '/distributor/:id',
}

const SalesPointZoom = {
  component: views.SalesPointZoom,
  path: '/sales-point/:id/:sort?/:order?',
}

const ExportScene = {
  component: views.ExportScene,
  path: '/export',
}

const ImportScene = {
  component: views.ImportScene,
  path: '/import',
}

const GDPRScene = {
  component: views.GDPRScene,
  path: '/rgpd',
}

const FbZoom = {
  component: views.FbZoom,
  path: '/france-boissons/:id',
}

const FbFileZoom = {
  component: views.FbFileZoom,
  path: '/fb-files/:id/:userId',
}

export default [Home, DistribZoom, SalesPointZoom, ExportScene, ImportScene, GDPRScene, FbZoom, FbFileZoom, CollectFB]
