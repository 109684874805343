import { file } from '../common'

export const salesPointQuery = (salesPointId) => {
  return `{
    salesPoint(id: ${salesPointId}) {
      id
      name
      AMLVolume
      AMAVolume
      PADHKOnlyVolume
      PADLeaderVolume
      AMLAmount
      AMAAmount
      PADHKOnlyAmount
      PADLeaderAmount
      PADHKOnlyDistributorAmount
      distributor {
        id
        status {
          id
          name
        }
      }
      error {
        id
      }
      address {
        address
        city
        zipcode
      }
      files {
        ${file}
      }
    }
  }`
}
