import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { useState } from 'react'

ExportCard.propTypes = {
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  isDownloading: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
}
ExportCard.defaultProps = {
  isDownloading: false,
}

export default function ExportCard({ inputs, isDownloading, title, onChange, onValidate }) {
  const [selected, setSelected] = useState('')
  return (
    <Paper style={{ padding: '20px', marginTop: '20px', marginBottom: '20px' }}>
      <Typography variant="subtitle1" component="h3">
        <b>{title}</b>
      </Typography>
      <Box display="flex" alignItems="center">
        <Box flexGrow={1}>
          <RadioGroup
            aria-label="target"
            name="target"
            value={selected}
            onChange={(event) => setSelected(event.target.value)}
          >
            {inputs.map((input, index) => (
              <FormControlLabel
                key={`radio-${index}`}
                value={input.value}
                control={<Radio color="default" style={{ color: '#205527' }} />}
                label={input.label}
                onClick={() => onChange(input.value)}
              />
            ))}
          </RadioGroup>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={onValidate}
            disabled={isDownloading}
            endIcon={isDownloading ? <CircularProgress style={{ height: '20px', width: '20px' }} /> : ''}
          >
            Valider
          </Button>
        </Box>
      </Box>
    </Paper>
  )
}
