import { SALESPOINT_STATUS } from './constants'

export function sortArrayByValue(array, value) {
  return array.sort((a, b) => {
    return a[value] > b[value] ? 1 : -1
  })
}

export function getSalesPointSatus(files) {
  if (files.length === 0) return SALESPOINT_STATUS.toComplete // Case the sales point doesn't have file because it has been added manually
  return files.some((file) => file.volume === null) ? SALESPOINT_STATUS.toComplete : SALESPOINT_STATUS.completed
}

export const getAMLStatusError = (salesPoint) => !!salesPoint.error

export const getServiceStatusError = (service, salesPoint) => {
  const filteredFiles = salesPoint.files?.filter((file) => file.service.name === service)
  return !!salesPoint.error || filteredFiles.some((file) => !!file.error)
}
