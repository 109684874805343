import { Box, LinearProgress, Paper, Typography } from '@material-ui/core'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: 'black',
  },
}))(LinearProgress)

export default class ProgressBar extends React.Component {
  render() {
    return (
      <Box component={Paper} p={2}>
        <Typography variant="overline">
          <Box mb={1}>{this.props.title}</Box>
        </Typography>
        <Box display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <BorderLinearProgress variant="determinate" value={this.props.value} />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">
              {this.props.value}%
            </Typography>
          </Box>
        </Box>
      </Box>
    )
  }
}
