import { Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

DetailsButton.propTypes = {
  to: PropTypes.string,
  data: PropTypes.object,
}
export default function DetailsButton({ to, data }) {
  return (
    <Button
      variant="contained"
      component={Link}
      to={{
        pathname: to,
        state: data,
      }}
      size="small"
      color="secondary"
    >
      Détails
    </Button>
  )
}
