export const updateDistributorStatus = (distributorId, statusId) => {
  return `mutation {
    updateDistributorStatus(distributorId: ${distributorId}, statusId: ${statusId}) {
      id
      status {
        id
        name
      }
    }
  }`
}

export const updateDistributorEmail = (distributorId, email) => {
  return `mutation {
    updateDistributorEmail(distributorId: ${distributorId}, email: "${email}") {
      id
      email
    }
  }`
}
