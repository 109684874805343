import { Box, IconButton, Tooltip } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import PropTypes, { string } from 'prop-types'
import { useContext, useEffect, useRef, useState } from 'react'
import { GoAlert } from 'react-icons/go'
import API from '../../../api/api'
import { updateFileVolume } from '../../../api/mutations/fileMutations'
import { UserContext } from '../../../contexts/userContext'
import { ALERT_SEVERITY, DEFAULT_SUCCESS_ALERT, SALESPOINT_STATUS, USER_ROLES } from '../../../utils/constants'
import ActionTableLayout from '../../Layout/ActionTableLayout'
import BaseTable from '../BaseTable'
import CollapsibleTable from '../CollapsibleTable'
import { checkFormat, getCurrentFileFromProps, getFilesData, handleEnter, handleMenuClick } from './functions'

FilesTable.propTypes = {
  files: PropTypes.array.isRequired,
  hasCategory: PropTypes.bool.isRequired,
  hideAmounts: PropTypes.objectOf(PropTypes.bool).isRequired,
  selectedFile: PropTypes.object.isRequired,
  status: string,
  title: PropTypes.string.isRequired,
  onMenuClick: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onSelectFile: PropTypes.func.isRequired,
}

export default function FilesTable({
  files,
  hasCategory,
  hideAmounts,
  selectedFile,
  status,
  title,
  onMenuClick,
  onSave,
  onSelectFile,
}) {
  const data = getFilesData(files, hasCategory, hideAmounts)
  const [filesData, setFiles] = useState(data)
  const [displayOnlyErrors, setDisplayOnlyErrors] = useState(false)
  const { userRole } = useContext(UserContext)

  const initialOpen = () => {
    const open = {}
    if (hasCategory) filesData.forEach((category) => (open[category.id] = false))
    return open
  }
  const [open, setOpen] = useState(initialOpen)

  const inputRefs = useRef([])
  const disableInput = !(status === SALESPOINT_STATUS.toComplete || userRole !== USER_ROLES.commercial)

  useEffect(() => {
    setFiles(getFilesData(files, hasCategory, hideAmounts))
  }, [files])

  useEffect(() => {
    if (!displayOnlyErrors) {
      setFiles(getFilesData(files, hasCategory, hideAmounts))
      return
    }

    let result = []
    if (hasCategory) {
      files.forEach((category) => {
        const filteredFiles = category.files.filter((file) => file.error)
        if (filteredFiles.length > 0) result.push({ ...category, files: filteredFiles })
      })
    } else result = files.filter((file) => file.error)
    setFiles(getFilesData(result, hasCategory, hideAmounts))
  }, [displayOnlyErrors])

  const headCells = () => {
    const cells = [
      { id: 'type', label: hasCategory ? 'Catégorie' : 'Type', width: 95, align: 'left' },
      { id: 'product', label: 'Marque', width: 160, align: 'left' },
      { id: 'code', label: 'Code dossier Sparc', width: 80 },
      { id: 'discount', label: '€/HL', width: 60 },
      { id: 'distributorDiscount', label: 'Remise distributeur (€/HL)' },
      { id: 'volume', label: 'HL réels (sur la période)', width: 115 },
      { id: 'amount', label: 'Montant', width: 85 },
      { id: 'months', label: 'Nombre de mois', width: 95 },
      { id: 'dates', label: 'Dates de début/fin', width: 105 },
      { id: 'previousVolume', label: 'Volume réalisé N-1 (en HL)', width: 80 },
      { id: 'objectiveVolume', label: 'Objectif contrat (en HL)', width: 100 },
      { id: 'objectiveAmount', label: 'Objectif contrat (en €)', width: 100 },
    ]
    if (hasCategory) {
      cells.splice(4, 1)
      cells.unshift({ id: 'order', label: '', width: 60 })
    }
    return cells
  }

  async function onSaveAction(e) {
    const { value, name } = e.target
    const alert = checkFormat(value)
    if (alert) {
      onSave(alert, null)
      return
    }
    const newVolume = parseFloat(value.replace(',', '.'))
    const fileId = parseInt(name.substring(5))
    const currentFile = getCurrentFileFromProps(files, hasCategory, fileId)
    if (!value || currentFile.volume === newVolume) return

    const res = await API.queryAPI(updateFileVolume(fileId, newVolume))
    const newFile = { ...currentFile, volume: newVolume, amount: res.updateFileVolume.amount }
    res.updateFileVolume
      ? onSave(DEFAULT_SUCCESS_ALERT, newFile)
      : onSave(
          { severity: ALERT_SEVERITY.error, message: 'Erreur lors de la transmission du volume !', open: true },
          null,
        )
  }

  function customCells(cell, item, index, arrayData) {
    switch (cell) {
      case 'volume':
        if (hasCategory && item.files) return item.volume
        return (
          <input
            ref={(ref) => (inputRefs.current[index] = ref)}
            type="text"
            style={{ width: '60px' }}
            defaultValue={item.volume}
            name={'file-' + item.id}
            onKeyDown={(e) => handleEnter(e, inputRefs.current[index === arrayData.length - 1 ? 0 : index + 1])}
            onBlur={onSaveAction}
            disabled={disableInput}
          />
        )
      case 'months':
        if (item.months !== 12)
          return (
            <>
              <Tooltip title={item.monthsTooltip}>
                <span style={{ color: item.contentiousDate !== null ? 'red' : 'orange', marginRight: 10 }}>
                  <GoAlert />
                </span>
              </Tooltip>
              {item.months}
            </>
          )
        else return item.months
      case 'order':
        if (hasCategory && !item.files) return ''
        return (
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen({ ...open, [item.id]: !open[item.id] })}
          >
            {open[item.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        )
      default:
        return item[cell]
    }
  }

  function collapsibleTable(category) {
    if (!hasCategory) return <></>
    const files = getFilesData(category.files, false, getFilesData)
    return (
      <CollapsibleTable
        customCells={customCells}
        items={files}
        headCells={headCells()}
        open={open[category.id]}
        orderBy="product"
      />
    )
  }

  return (
    <Box mb={2}>
      <ActionTableLayout
        display={displayOnlyErrors}
        menuItems={['Ajouter une marque', 'Ajouter un dossier', "Modifier l'€/HL", 'Modifier les dates']}
        switchLabels={['Afficher tous les dossiers', 'Afficher uniquement les écarts']}
        title={title}
        onChange={() => setDisplayOnlyErrors(!displayOnlyErrors)}
        onMenuClick={(action) => onMenuClick(handleMenuClick(action, selectedFile))}
      >
        <BaseTable
          collapsibleTable={collapsibleTable}
          customCells={customCells}
          defaultOrderBy="code"
          headCells={headCells()}
          selectedRowId={selectedFile.id ?? null}
          tableData={filesData}
          onSelect={(item) => onSelectFile(files.find((current) => current.id === item.id))}
        />
      </ActionTableLayout>
    </Box>
  )
}
