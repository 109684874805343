import { ERRORS } from '../../utils/constants'

export const addSalesPoint = (data) => {
  return `mutation {
    addSalesPoint(      
      name: "${data.name.toUpperCase()}",
      address: "${data.address}",
      city: "${data.city.toUpperCase()}",
      zipcode: "${data.zipcode}",
      distributorId: ${data.distributorId},
      adLogin: "${data.adLogin}",
      errorId: ${data.errorId}
    ){
      id
      name
      address {
        address
        city
        zipcode
      }
      error {
        id
        name
      }
      files {
        id
      }
    }
  }`
}

export const updateSalesPointAttachment = (salesPointId) => {
  return `mutation {
    updateSalesPointError(salesPointId: ${salesPointId}, errorId: ${ERRORS.salesPointAttachment}) {
      id
      error {
        id
        name
      }
    }
  }`
}
