import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core'
import PropTypes from 'prop-types'

CustomizedCheckbox.propTypes = {
  isCompleted: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default function CustomizedCheckbox({ isCompleted, label, onChange, isDisabled = false }) {
  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            disabled={isDisabled}
            checked={isCompleted}
            size="small"
            color="default"
            onChange={onChange}
            name="customized-checkbox"
            style={{ color: '#205527' }}
          />
        }
        label={<span style={{ fontSize: 14 }}>{label}</span>}
      />
    </FormGroup>
  )
}
