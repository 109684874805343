import * as msal from '@azure/msal-browser'

const msalConfig = {
  auth: {
    clientId: '0db1e22c-d154-4119-b8a4-b8dff0157078',
    authority: 'https://login.microsoftonline.com/66e853de-ece3-44dd-9d66-ee6bdf4159d4',
    redirectUri: '/',
  },
  cache: { cacheLocation: 'localStorage' },
  storeAuthStateInCookie: false,
}

const loginRequest = {
  scopes: ['openid', 'profile'],
}

const apiScopeRequest = {
  scopes: ['api://22cefdc4-bf82-4eb7-9e8e-d9c7df2a6ba2/access_as_user'],
}

const msalInstance = new msal.PublicClientApplication(msalConfig)

export { msalInstance, loginRequest, apiScopeRequest }
