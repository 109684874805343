import moment from 'moment'
import API from '../../../api/api'
import { productsByMissingCategoriesQuery, productsQuery } from '../../../api/queries/productQueries'
import { FILE_ACTIONS, SERVICES_ID } from '../../../utils/constants'
import { sortArrayByValue } from '../../../utils/functions'

export const getUpdatedFiles = (filesList, filesId, values) => {
  const files = filesList
  filesId.forEach((fileId) => {
    const fileIndex = filesList.indexOf(filesList.find((file) => file.id === fileId))
    Object.entries(values).forEach(([key, value]) => (files[fileIndex][key] = value))
  })
  return files
}

export const getFormatedDate = (date) =>
  /[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(date) === true ? date : moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')

export const checkProductForSelectedDates = (currentProduct, startingDate, endingDate, files) => {
  const matchingFiles = files.filter((file) => file.product.id === currentProduct)
  matchingFiles.some(
    (file) =>
      moment(startingDate).isBetween(file.startingDate, file.endingDate) ||
      moment(endingDate).isBetween(file.startingDate, file.endingDate),
  )
}

export const getDisabled = (disabled, keys) => {
  const updatedDisabled = {}
  Object.keys(disabled).forEach((key) => {
    if (!keys.includes(key)) updatedDisabled[key] = true
  })
  return updatedDisabled
}

const getNonExistingCategorizedProducts = async (files, sparcCode) => {
  const existingFiles = files.filter((file) => file.sparcCode === sparcCode && file.service.id === SERVICES_ID.AML)
  const categoriesIds = [...new Set(existingFiles.flatMap((file) => file.product.category?.id))]
  const result = await API.queryAPI(productsByMissingCategoriesQuery(categoriesIds))
  return result.productsByMissingCategories
}

const getNonExistingProducts = (files, products, sparcCode) => {
  const existingProducts = files
    .filter((file) => file.sparcCode === sparcCode && file.service.id !== SERVICES_ID.AML)
    .map((file) => file.product)
  return products.filter((product) => !existingProducts.find((item) => item.id === product.id))
}

export const getProducts = async (action, currentService, files, sparcCode, serviceId) => {
  let products
  if (serviceId === SERVICES_ID.AML && action === FILE_ACTIONS.addProduct) {
    products = await getNonExistingCategorizedProducts(files, sparcCode)
  } else {
    const result = await API.queryAPI(productsQuery)
    products = result.products
    if (action === FILE_ACTIONS.addProduct) products = getNonExistingProducts(files, products, sparcCode)
  }

  if (currentService === SERVICES_ID.AML && action === FILE_ACTIONS.addFile)
    products = products.filter((product) => product.category !== null)
  products = sortArrayByValue(products, 'name')
  return products.map((product) => ({ ...product, name: product.name }))
}

export const getFilesIds = (selectedFile) =>
  selectedFile.files ? selectedFile.files.map((file) => file.id) : [selectedFile.id]
