import { ALERT_MESSAGE } from '../utils/constants'

export default {
  queryAPI: (query) => {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        body: JSON.stringify({
          query: query,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.errors) reject(response.errors[0].message)
          resolve(response.data)
        })
        .catch(() => {
          reject(ALERT_MESSAGE.error)
        })
    })
  },

  restAPI: (endpoint, method, body = null) => {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
        method: method,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        body: body,
      })
        .then((res) => res.json())
        .then((response) => {
          resolve(response)
        })
    })
  },
}
