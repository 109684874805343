import { Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import { getSalesPointSatus } from '../../../utils/functions'
import DetailsButton from '../../Button/DetailsButton'
import ActionTableLayout from '../../Layout/ActionTableLayout'
import BaseTable from '../BaseTable'
import {
  displayAMAmount,
  displayPADAmount,
  displayPADDistributorAmount,
  displayValue,
  sumKeys,
  sumTypes,
} from './function'

SalesPointsTable.propTypes = {
  distributor: PropTypes.object.isRequired,
  selectedSalesPoint: PropTypes.object,
  onMenuClick: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default function SalesPointsTable({ distributor, selectedSalesPoint, onMenuClick, onSelect }) {
  const salesPoints = distributor.salesPoints
  const headCells = [
    { id: 'salesPoint', label: 'Point de vente', align: 'left' },
    { id: 'city', label: 'Ville', align: 'left' },
    { id: 'zipcode', label: 'Code postal', align: 'left' },
    { id: 'status', label: 'Statut', align: 'left' },
    { id: 'AMVolume', label: 'Volume AMA/AML (en HL)' },
    { id: 'AMAmount', label: 'Montant AMA/AML (en €)' },
    { id: 'PADVolume', label: 'Volume PAD (en HL)' },
    { id: 'PADAmount', label: 'Montant annuel PAD(en €)' },
    { id: 'PADDistributorAmount', label: 'Montant PAD à refacturer au distributeur (en €)' },
    { id: 'rowNumber', label: 'Nombre de lignes (dont écarts)' },
    { id: 'action', label: 'Accéder aux dossiers' },
  ]
  const salesPointsData = () =>
    salesPoints.map((salesPoint) => {
      return {
        id: salesPoint.id,
        salesPoint: salesPoint.name.toLowerCase(),
        city: salesPoint.address.city.toLowerCase(),
        zipcode: salesPoint.address.zipcode,
        status: getSalesPointSatus(salesPoint.files),
        AMVolume: displayValue([sumKeys.AML, sumKeys.AMA], sumTypes.volume, salesPoint),
        AMAmount: displayAMAmount(salesPoint),
        PADVolume: displayValue([sumKeys.PADHKOnly, sumKeys.PADLeader], sumTypes.volume, salesPoint),
        PADAmount: displayPADAmount(salesPoint),
        PADDistributorAmount: displayPADDistributorAmount(salesPoint),
        rowNumber: `${Object.keys(salesPoint.files).length} (${
          salesPoint.files.filter((file) => !!file.error).length
        })`,
        error: salesPoint.error,
      }
    })

  const customCells = (cell, currentItem, _index, arrayData) => {
    if (cell === 'action')
      return <DetailsButton to={`/sales-point/${currentItem.id}`} data={{ ...distributor, salesPoints: arrayData }} />
  }

  return (
    <Box>
      <ActionTableLayout
        menuItems={['Ajouter un point de vente', 'Modifier le rattachement']}
        title="Synthèse des points de vente"
        onMenuClick={onMenuClick}
      >
        <BaseTable
          customCells={customCells}
          defaultOrderBy="salesPoint"
          headCells={headCells}
          selectedRowId={selectedSalesPoint?.id ?? null}
          tableData={salesPointsData()}
          onSelect={(item) => onSelect(salesPoints.find((current) => current.id === item.id))}
        />
      </ActionTableLayout>
    </Box>
  )
}
