import { formatNumber, formatPrice } from '../../utils/format'

// TODO: fix this function
export const getServicesStatusErrors = (salesPoints) => {
  return {
    PADLeader: null,
    PADHKOnly: null,
  }
}

export const getSums = (distributor, errors) => {
  const amounts = distributor.totalAMLAmount + distributor.totalAMAAmount
  const sums = [
    {
      title: 'Montant AML/AMA déjà versé à T1/T2/T3',
      value: formatPrice(distributor.payedAmount),
      error: false,
    },
    {
      title: 'Montant AML/AMA total annuel',
      value: formatPrice(amounts),
      error: errors.AML || errors.AMA,
    },
    {
      title: 'Montant AMA/AML T4',
      value: formatPrice(amounts - distributor.payedAmount),
      error: errors.AML || errors.AMA,
    },
  ]
  return sums
}

const calculateVolumeByService = (distributor, service) => {
  const files = distributor.fbPadFiles.filter((file) => file.service === service)

  return files.reduce((acc, cur) => {
    const fileVolumes = cur.fbPadLines.reduce((fileAcc, fileCur) => {
      return fileAcc + fileCur.volume
    }, 0)
    return acc + fileVolumes
  }, 0)
}

const calculateAmountByService = (distributor, service) => {
  const files = distributor.fbPadFiles.filter((file) => file.service === service)

  return files.reduce((acc, cur) => {
    const fileAmounts = cur.fbPadLines.reduce((fileAcc, fileCur) => {
      const paybackHT =
        (fileCur.volume - fileCur.free_HE - fileCur.free_FB) * (fileCur.discount_HE + fileCur.discount_FB)
      return fileAcc + paybackHT * (1 + fileCur.tax_rate / 100)
    }, 0)
    return acc + fileAmounts
  }, 0)
}

export const getCardsValues = (distributor) => {
  return [
    {
      title: 'PAD HE Leader',
      volume: formatNumber(calculateVolumeByService(distributor, 'PAD Heineken Leader')),
      amount: formatNumber(calculateAmountByService(distributor, 'PAD Heineken Leader')),
      className: 'children',
    },
    {
      title: 'PAD HE Seul',
      volume: formatNumber(calculateVolumeByService(distributor, 'PAD Heineken Seul')),
      amount: formatNumber(calculateAmountByService(distributor, 'PAD Heineken Seul')),
      className: 'children',
    },
    {
      title: 'PAD FB Leader',
      volume: formatNumber(calculateVolumeByService(distributor, 'PAD France Boissons Leader')),
      amount: formatNumber(calculateAmountByService(distributor, 'PAD France Boissons Leader')),
      className: 'children',
    },
    {
      title: 'PAD FB Seul',
      volume: formatNumber(calculateVolumeByService(distributor, 'PAD France Boissons Seul')),
      amount: formatNumber(calculateAmountByService(distributor, 'PAD France Boissons Seul')),
      className: 'children',
    },
  ]
}
