import { Box, IconButton, Tooltip } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import PropTypes, { string } from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { GoAlert } from 'react-icons/go'
import { UserContext } from '../../../contexts/userContext'
import { USER_ROLES } from '../../../utils/constants'
import ActionTableLayout from '../../Layout/ActionTableLayout'
import BaseTable from '../BaseTable'
import CollapsibleTable from '../CollapsibleTable'
import { getFilesData, handleMenuClick } from './functions'

FbPadLinesTable.propTypes = {
  files: PropTypes.array.isRequired,
  hasCategory: PropTypes.bool.isRequired,
  hideAmounts: PropTypes.objectOf(PropTypes.bool).isRequired,
  selectedFile: PropTypes.object.isRequired,
  status: string,
  title: PropTypes.string.isRequired,
  onMenuClick: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onSelectFile: PropTypes.func.isRequired,
}

export default function FbPadLinesTable({
  files,
  hasCategory,
  hideAmounts,
  selectedFile,
  status,
  title,
  onMenuClick,
  onSave,
  onSelectFile,
}) {
  const data = getFilesData(files)
  const [filesData, setFiles] = useState(data)
  const [displayOnlyErrors, setDisplayOnlyErrors] = useState(false)
  const { userRole } = useContext(UserContext)

  const initialOpen = () => {
    const open = {}
    if (hasCategory) filesData.forEach((category) => (open[category.id] = false))
    return open
  }
  const [open, setOpen] = useState(initialOpen)

  useEffect(() => {
    setFiles(getFilesData(files, hasCategory, hideAmounts))
  }, [files])

  useEffect(() => {
    if (!displayOnlyErrors) {
      setFiles(getFilesData(files, hasCategory, hideAmounts))
      return
    }

    let result = files.filter((file) => {
      if (file.lineStatus === 3) return false
      else if (file.error) return true
      else if (file.replaceLine) return true
      return false
    })
    setFiles(getFilesData(result, hasCategory, hideAmounts))
  }, [displayOnlyErrors])

  const headCells = () => {
    const cells = [
      { id: 'type', label: 'Catégorie' },
      { id: 'product', label: 'Marque' },
      { id: 'volume', label: 'Volumes réalisés sur la période' },
      { id: 'freeHE', label: 'Gratuités HE' },
      { id: 'freeFB', label: 'Gratuités FB' },
      { id: 'discountHE', label: 'Remise HE' },
      { id: 'discountFB', label: 'Remise FB' },
      { id: 'paybackHT', label: 'Ristourne HT' },
      { id: 'taxRate', label: 'Taux de TVA' },
      { id: 'paybackTTC', label: 'Ristourne TTC' },
      { id: 'units', label: 'Unités' },
    ]
    if (hasCategory) {
      cells.splice(4, 1)
      cells.unshift({ id: 'order', label: '', width: 60 })
    }
    return cells
  }

  const menuItems =
    userRole === USER_ROLES.admin
      ? ['Ajouter une marque', 'Modifier une marque', 'Valider un écart', 'Refuser un écart']
      : ['Ajouter une marque', 'Modifier une marque']

  function customCells(cell, item, index, arrayData) {
    switch (cell) {
      case 'months':
        if (item.months !== 12)
          return (
            <>
              <Tooltip title={item.monthsTooltip}>
                <span style={{ color: item.contentiousDate !== null ? 'red' : 'orange', marginRight: 10 }}>
                  <GoAlert />
                </span>
              </Tooltip>
              {item.months}
            </>
          )
        else return item.months
      case 'order':
        if (hasCategory && !item.files) return ''
        return (
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen({ ...open, [item.id]: !open[item.id] })}
          >
            {open[item.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        )
      default:
        return item[cell]
    }
  }

  function collapsibleTable(category) {
    if (!hasCategory) return <></>
    const files = getFilesData(category.files, false, getFilesData)
    return (
      <CollapsibleTable
        customCells={customCells}
        items={files}
        headCells={headCells()}
        open={open[category.id]}
        orderBy="product"
      />
    )
  }

  return (
    <Box mb={2}>
      <ActionTableLayout
        display={displayOnlyErrors}
        menuItems={menuItems}
        switchLabels={['Afficher tous les dossiers', 'Afficher uniquement les écarts']}
        title={title}
        onChange={() => setDisplayOnlyErrors(!displayOnlyErrors)}
        onMenuClick={(action) => onMenuClick(handleMenuClick(action, selectedFile))}
        disableButton={status}
      >
        <BaseTable
          collapsibleTable={collapsibleTable}
          customCells={customCells}
          defaultOrderBy="code"
          headCells={headCells()}
          selectedRowId={selectedFile.id ?? null}
          tableData={filesData}
          onSelect={(item) => onSelectFile(files.find((current) => current.id === item.id))}
        />
      </ActionTableLayout>
    </Box>
  )
}
