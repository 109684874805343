import { useMsal } from '@azure/msal-react'
import { Box, Button, Typography } from '@material-ui/core'
import React from 'react'
import logo from '../../logo.png'
import './style.css'

export default function WelcomeBox() {
  const { instance } = useMsal()

  return (
    <Box mt={-2} width="100%">
      <Box
        style={{
          backgroundColor: '#205527',
          height: '160px',
          padding: '20px',
          color: 'white',
        }}
      >
        <Box mt={15}>
          <Typography style={{ fontWeight: 'bold' }}>Bienvenue dans HectoR,</Typography>
        </Box>
        <Box>
          <Typography>l&apos;application de collecte des volumes réels.</Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        <img src={logo} alt="Logo" />
      </Box>

      <Box display="flex" justifyContent="center">
        <Button variant="contained" onClick={() => instance.loginRedirect()} className="connect-button" size="small">
          Se connecter
        </Button>
      </Box>
    </Box>
  )
}
