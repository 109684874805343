import { Collapse, TableCell, TableRow } from '@material-ui/core'
import PropTypes from 'prop-types'
import BaseTable from '../BaseTable'

CollapsibleTable.propTypes = {
  customCells: PropTypes.func,
  items: PropTypes.array.isRequired,
  headCells: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  orderBy: PropTypes.string.isRequired,
}

CollapsibleTable.defaultProps = {
  open: false,
}

export default function CollapsibleTable({ customCells, items, headCells, open, orderBy }) {
  return (
    <TableRow>
      <TableCell style={{ padding: 0 }} colSpan={13}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <BaseTable
            customCells={customCells}
            defaultOrderBy={orderBy}
            headCells={headCells}
            isCollapsed
            tableData={items}
          />
        </Collapse>
      </TableCell>
    </TableRow>
  )
}
