export const createComment = (comment, userId, padId) => {
  return `mutation {
        createComment(comment:"${comment}", userId:${userId}, padId:${padId}) {
            id
            value
            date
            user {
                firstname
            }
            padId
        }
    }`
}
