import { Grid, Typography } from '@material-ui/core'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import API from '../../../api/api'
import { updateFileEndingDate } from '../../../api/mutations/fileMutations'
import { updateSalesPointAttachment } from '../../../api/mutations/salesPointMutations'
import { allDistributorsQuery } from '../../../api/queries/distributorQueries'
import { UserContext } from '../../../contexts/userContext'
import { sortArrayByValue } from '../../../utils/functions'
import DatePicker from '../DatePickerForm'
import DialogForm from '../DialogForm'
import Select from '../SelectForm'
import CommonTextField from '../TextFieldForm'

SalesPointAttachmentForm.propTypes = {
  distributor: PropTypes.object,
  salesPoint: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onValidate: PropTypes.func,
}

export default function SalesPointAttachmentForm({ distributor, salesPoint, open, onClose, onValidate }) {
  const [distributors, setDistributors] = useState([])
  const [newDistributor, setDistributor] = useState({})
  const [date, setDate] = useState(null)
  const { userLogin } = useContext(UserContext)

  useEffect(async () => {
    const result = await API.queryAPI(allDistributorsQuery(userLogin))
    // Remove current distributor
    const distributors = result.user.distributors.filter((item) => item.id !== distributor.id)
    setDistributors(sortArrayByValue(distributors, 'name'))
  }, [])

  async function _handleValidate() {
    // TODO : check values format before saving
    const files = salesPoint.files.map((file) => file)
    for (const [index, file] of files.entries()) {
      await API.queryAPI(updateFileEndingDate(file.id, moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')))
      salesPoint.files[index].endingDate = moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
    }
    const newSalesPointResult = await API.queryAPI(updateSalesPointAttachment(salesPoint.id))
    onValidate({ ...salesPoint, error: newSalesPointResult.updateSalesPointError.error })
  }

  return (
    <DialogForm open={open} onValidate={_handleValidate} onClose={onClose} title="Modifier le rattachement">
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="body2">Le point de vente</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CommonTextField name="current-distributor" value={distributor.name} label="Distributeur" disabled={true} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CommonTextField name="name" value={salesPoint.name} label="Nom du point de vente" disabled={true} />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2" align="center">
            est à rattacher à
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Select
            name="distributor"
            value={newDistributor}
            onChange={(event) => setDistributor(event.target.value)}
            label="Distributeur"
            items={distributors}
            valueName="id"
            text="name"
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography variant="body2" align="center">
            à partir du
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <DatePicker name="date" value={date} onChange={(date) => setDate(date)} label="Date du début" />
        </Grid>
      </Grid>
    </DialogForm>
  )
}
